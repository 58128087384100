import ScreenConstants from '../ScreenConstants'
import ThreeMeshUI from 'three-mesh-ui';
import FontJSON from '../../../Fonts/Roboto-msdf.json';
import FontImage from '../../../Fonts/Roboto-msdf.png';
import FontBlackImage from '../../../Fonts/Roboto-Black.png'
import FontBlackJSON from '../../../Fonts/Roboto-Black-msdf.json';
import AnimationPanel from './AnimationPanel';
import Utils from '../../../utils.js'
import MainConfig from '../../../../main.config';
import {TextureLoader} from 'three';

class Article {
    constructor(props) {
        this.context = props.context;
        this.store = props.store;
        this.container = props.container
        this.mesh = null;
        this.poi = props.poi;
        this.load();
    }

    load() {
        /*
        articlecontainer
            articletitlecontainer
                articleTitleTextContainer
                    title
                articleSubTitleTextContainer
                    subtitle
            articleImgContainer
            articleMainContainer 
                maintext
         */
        this.articleContainer = new ThreeMeshUI.Block({
            width: 2,
            height: 2,
            backgroundColor: ScreenConstants.lightGray,
            backgroundOpacity: 1,
            fontColor: ScreenConstants.darkGrayFont,
            alignContent: 'center',
            contentDirection: 'column',
            justifyContent: 'start'
        })
        this.articleContainer.position.set(1, .6, .5);
        this.articleContainer.rotation.set(0.5, -.8, 0);
        this.articleContainer.scale.set(.5,.5,.5);

        this.container.add(this.articleContainer);

        //Title
        const articleTitleContainer = new ThreeMeshUI.Block({
            width: 1.8,
            height: 0.18,
            backgroundOpacity: 0,
            alignContent: 'left',
            contentDirection: 'column',
            justifyContent: 'start',
            margin: 0.1,
            offset: ScreenConstants.offset

        })
        this.articleContainer.add(articleTitleContainer)


        const articleTitleTextContainer = new ThreeMeshUI.Block({
            width: 1.8,
            height: 0.1,
            backgroundOpacity: 0,
            fontFamily: FontBlackJSON,
            fontTexture: FontBlackImage,
            fontSize: ScreenConstants.fontExtraBig,
            //alignContent: 'left',
            contentDirection: 'row',
            offset: ScreenConstants.offset
        })
        this.title = new ThreeMeshUI.Text({ content: "Empty Title" })
        articleTitleTextContainer.add(this.title)

        const articleSubTitleTextContainer = new ThreeMeshUI.Block({
            width: 1.8,
            height: 0.1,
            backgroundOpacity: 0,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSize: ScreenConstants.fontExtraBig,
            //alignContent: 'left',
            contentDirection: 'row',
            offset: ScreenConstants.offset
        })

        this.subTitle = new ThreeMeshUI.Text({ content: "Empty SubTitle" })
        articleSubTitleTextContainer.add(this.subTitle);
        articleTitleContainer.add(articleTitleTextContainer, articleSubTitleTextContainer)

        //IMage Part
        this.articleImageContainer = new ThreeMeshUI.Block({
            width: 1.8,
            height: 0.01,
            backgroundOpacity:0,
            offset:ScreenConstants.offset
        })
        this.articleContainer.add(this.articleImageContainer);

        //MAin Part
        const articleMainContainer = new ThreeMeshUI.Block({
            width: 1.8,
            height: 1.6,
            backgroundOpacity: 0,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSize: ScreenConstants.fontArticle,
            offset: ScreenConstants.offset,
            alignContent: 'left',
        })
        this.mainText = new ThreeMeshUI.Text({ content: "Empty Main Text" })
        articleMainContainer.add(this.mainText)
        this.articleContainer.add(articleMainContainer);

        //Animation
        this.animationPanel = new AnimationPanel({
            context: this.context,
            store: this.store,
            container: this.articleContainer
        });


        //WATCHERS
        this.store.watch(state => state.content.currentSubTab, (newValue, oldValue) => {
            this.OnSubTabChanges();
        })
        
        this.loader = new TextureLoader();
        this.OnSubTabChanges();
    }

    OnSubTabChanges() {


        if (this.store.state.content.currentPOI == null) {
            this.articleContainer.visible = false;
            return;
        } else {

            this.articleContainer.visible = true;
            this.poi = this.store.state.content.currentPOI;
            this.title.set({
                content: this.poi.Name
            })
            this.subTitle.set({
                content: this.poi.Subtitle
            })

            if (this.store.state.content.currentSubTab != null) {
                this.animationPanel.SetVisibility(false);
                this.animationPanel.Reset();

                this.store.state.content.currentSubTab.UISpecialElement.forEach(element => {
                    if (element.elements == "AnimationPlayer") {
                        this.animationPanel.SetVisibility(true);
                        //Utils.SetAnimation(this.store)
                    }
                });

                //Extract and load images
                
                this.articleImageContainer.set({
                    height: 0.01,
                    backgroundOpacity:0
                })

                var articleText = this.store.state.content.currentSubTab.subtabtext_component;
                var extracts = Utils.extractLinks(articleText)

                extracts.forEach((extract) => {
                    this.loader.load(MainConfig.CMS_BASE_URL + extract.processedURL, icon => {
                        this.articleImageContainer.set({
                            backgroundTexture: icon,
                            backgroundOpacity:1,
                            height: 1,
                        })
                    })
                    articleText = articleText.replace(extract.removeString,"")
                }
                )

                this.mainText.set({
                    content: articleText
                })

            }

        }

    }
}
export default Article