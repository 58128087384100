import TinyCity from '../../UI_Sections/TinyCity/TinyCity.vue';
import Explorer from '../../UI_Sections/Explorer/Explorer.vue';
import Dashboard from '../../UI_Sections/Dashboard/Dashboard.vue';
import Configurator from '../../UI_Sections/Configurator/Configurator.vue';

import Loader from '@/Components/Loader.vue';

import Vue from 'vue'
import { store } from '../../store/store'
import router from '../../router'


class VRRouter{
  constructor(opt){

    this.router = opt.router;
    this.store = opt.store;
    this.currentComponent = null;
    this.currentName = null;

    // this.holder = document.createElement("div");
    // document.body.appendChild(this.holder);

    console.log("VR ROUTER ",this.store);

    window.addEventListener("keydown", this.HandleKeyDown);


    this.router.beforeEach((to,from,next)=>{

      console.log("this.vr_router changed from " , from, " to " , to);

      
      this.currentName = to.name;
      if(this.currentName == 'ExplorerSingle'){
        this.currentName = 'Explorer'
      }

      this.fromName = from.name;
      if(this.fromName == 'ExplorerSingle'){
        this.fromName = 'Explorer'
      }


      console.log( "to params " , to , to.params);

      if(this.store.state.xrMode == "VR"){

        console.log("Params xrMode VR ", this.currentName,this.currentComponent );

        if(this.currentName == this.fromName){console.log("VRROUTER: SAME COMPOENT, DONT MOUNT OR DESTROY ",this.currentName,this.fromName); next(); return;}


        if(this.currentName != null && this.currentComponent != null){
          this.currentComponent.$destroy();
        }
        

        switch(this.currentName){
          case "Loader":
            console.log("Mount Loader ");
            this.currentComponent = new Vue({ 
              ...Loader, // stage-2 rest spread operator
              propsData: {
                routerQuery : Object.assign({}, to.query)
              },
              store, 
              router
            })
            this.currentComponent.$mount(this.CreateElement());
          break;
          case "TinyCity":
            console.log("Mount Loader ");
            this.currentComponent = new Vue({ 
              ...TinyCity, // stage-2 rest spread operator
              propsData: {
                routerQuery : Object.assign({}, to.query)
              },
              store, 
              router
            })
            this.currentComponent.$mount(this.CreateElement());
          break;
          case "Configurator":
            console.log("Mount Config ");
            this.currentComponent = new Vue({ 
              ...Configurator, // stage-2 rest spread operator
             store, 
             router
            })
            this.currentComponent.$mount(this.CreateElement());
          break;
          case "Explorer":
            console.log("Mount Explorer ");
            this.currentComponent = new Vue({ 
              ...Explorer, // stage-2 rest spread operator
             store, 
             router
            })
            this.currentComponent.$mount(this.CreateElement());
          break;
          case "Dashboard":
            console.log("Mount Dashboard ");
            this.currentComponent = new Vue({ 
              ...Dashboard, // stage-2 rest spread operator
             store, 
             router
            })
            this.currentComponent.$mount(this.CreateElement());
          break;
        }

        
      }

      next();
    })

  }

  HandleKeyDown = (e) => {
    console.log(e.keyCode);

    switch(e.keyCode){
      case 69:
        this.router.push({path: "/VR/apartmentbuilding/configurator"})
        break;
        case 65:
        this.router.push({path: "/VR/familyhouse/configurator"})
        break;
        case 68:
        this.router.push({path: "/VR/apartment/explore"})
        break;  
    }

  }
  CreateElement(){
    var element = document.createElement("div");
    element.className = "shadow-vr-dom";
    document.body.appendChild(element);
    return element;
  }
}

export default VRRouter;