import {BoxGeometry, Color, Group, Mesh, MeshBasicMaterial} from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import ScreenConstants from './ScreenConstants';

import FontBoldImage from '../../Fonts/Roboto-Bold.png';
import FontBoldJSON from '../../Fonts/Roboto-Bold-msdf.json';

class VRProgress{
  constructor(props){
    this.xr = props.xr;
    this.store = props.store;
    this.router = props.router;

    this.xr.Events.addEventListener("ChangeScene" , this.ChangeScene);
    this.xr.Events.addEventListener("OnAnimationLoop" , this.Animate);

    this.progressBar = new Group();
    this.progressBar.visible = false;
    
    //this.xr.SceneController.AddToScene('UI' , this.progressBar);
    this.xr.Controls.ui_cameraHelper.add(this.progressBar);

    this.boxBG = new Mesh(new BoxGeometry(1.05,.15,.01), new MeshBasicMaterial({
      color : (new Color(0xffffff)).convertSRGBToLinear(),
      toneMapped : false
    }));
    this.boxBG.position.set(0,0,-.01);
    this.progressBar.attach(this.boxBG);
    
    this.box = new Mesh(new BoxGeometry(1,.1,.01), new MeshBasicMaterial({
      color : (new Color(ScreenConstants.orange)),
      toneMapped : false
    }));
    this.progressBar.attach(this.box);
    this.box.scale.setX(0);
    this.box.position.setX(-0.5)

    this.progressIndicator = new ThreeMeshUI.Block({
      contentDirection: 'row',
      justifyContent: 'center',
      height: .2,
      width: .5,
      backgroundColor: ScreenConstants.orange,
      backgroundOpacity: 1,
      fontSize: ScreenConstants.fontBig,
      fontColor: ScreenConstants.white,
      fontFamily: FontBoldJSON,
      fontTexture: FontBoldImage,
    });

    this.progressIndicator.position.set(0,.2,0);
    this.progressBar.attach(this.progressIndicator);

    this.progressTitle = new ThreeMeshUI.Text({ content: "Gleich geht's weiter: 0%" });
    this.progressIndicator.add( this.progressTitle );
    

   
        
    this.progressIndicator.isClickEnabled = true;
    this.progressIndicator.setupState({
        state: "selected",
        attributes: {
          backgroundColor: ScreenConstants.orange
        },
        onSet: () => {
            this.store.state.audio.controller.PlaySound("menu.click", false);

            //this.store.dispatch("content/SetCurrentPOI", this.poi);
            this.router.push(this.router.currentRoute.query.nextPath);

            this.progressBar.visible = false;
        }
    });

    this.progressIndicator.setupState({
        state: "idle",
        attributes: {
          backgroundColor: ScreenConstants.orange
        },
        onSet: () => {
          // console.log("idle");  
        }
    });

    this.progressIndicator.setupState({
      state : "hovered",
      attributes: {
        backgroundColor: ScreenConstants.lightOrange
      }
    });
    this.xr.Controls.ActiveObjects.push(this.progressIndicator);



    this.progressBar.position.set(0, 1.2, 20);

    
    this.store.watch(state => state.world.roomProgress , this.OnProgress);
    this.store.watch(state => state.world.sceneReady , this.OnSceneReady);

  }


  OnProgress = (newProgress, oldProgress)=>{
    // console.log("OnProgress",newProgress);
    // console.log("OnProgress",this.router.currentRoute);

    const progressObject = this.store.state.world.roomProgress;
    const loadObjectNames = this.router.currentRoute.query.areasToLoad;

    //console.log("loadObjectNames" , loadObjectNames);

    if(this.router.currentRoute.name == 'TinyCity' || typeof(loadObjectNames) == "undefined"){
      this.progressBar.visible = false;
      return;
    }else{
      this.progressBar.visible = true;
    }


    const loadObjectCount = loadObjectNames.length;

    const progress = loadObjectNames
      .map((objName) => {
        return progressObject[objName].progress;
      })
      .reduce((a, b) => a + b);

      // console.log("progress" , progress / loadObjectCount);
//    this.CheckLoading(progress / loadObjectCount);

      var visibleProgress = (progress / loadObjectCount);
    
    
    this.box.scale.setX(visibleProgress);
    this.box.position.setX(-0.5 * (1-visibleProgress))
    
    if(visibleProgress == 1){
      this.progressTitle.set({
        content: "Los gehts"
      });
      
      // console.log("this.currentRoute" , this.router.currentRoute.params);

    }else{

      this.progressTitle.set({
        content: "Gleich geht's weiter: " + Math.floor(visibleProgress* 100)+ "%"
      });
    }

   // this.xr.Scene.remove(this.progressBar);
  }
  
  OnSceneReady = ()=>{
    // console.log("SCENE READY")
    this.progressBar.visible = false;
    //this.xr.Scene.remove(this.progressBar);
  }

  ChangeScene = (opt) => {
    // console.log("VRProgress" , opt);

    //opt.newScene.attach(this.progressBar);
  }
  Animate = ()=>{

    // if(!this.store.state.world.sceneReady){
    //   this.xr.Controls.SetPosition(0,1.7,22);
    //   this.xr.Controls.SetTarget(0,0,0);

    // }

  }
}

export default VRProgress;