import { Group, Mesh,MeshBasicMaterial } from "three/build/three.module";
import { TimeShort, ViewMode } from "../../Enum/Enums";
import NavBtnMesh from '../../models/VR_UI_piece.glb';
import NavTinycityBtnMesh from '../../models/VR_UI_tinycity.glb';

import dashboard_config_normal from '../../models/textures/dashboard_mainnavi/dashboard_config_normal.png';
import dashboard_config_selected from '../../models/textures/dashboard_mainnavi/dashboard_config_selected.png';
import dashboard_config_hovered from '../../models/textures/dashboard_mainnavi/dashboard_config_hovered.png';

import dashboard_dashboard_normal from '../../models/textures/dashboard_mainnavi/dashboard_dashboard_normal.png';
import dashboard_dashboard_selected from '../../models/textures/dashboard_mainnavi/dashboard_dashboard_selected.png';
import dashboard_dashboard_hovered from '../../models/textures/dashboard_mainnavi/dashboard_dashboard_hovered.png';

import dashboard_explorer_normal from '../../models/textures/dashboard_mainnavi/dashboard_explorer_normal.png';
import dashboard_explorer_selected from '../../models/textures/dashboard_mainnavi/dashboard_explorer_selected.png';
import dashboard_explorer_hovered from '../../models/textures/dashboard_mainnavi/dashboard_explorer_hovered.png';

import dashboard_tinycity_normal from '../../models/textures/dashboard_mainnavi/dashboard_tinycity_normal.png';
import dashboard_tinycity_selected from '../../models/textures/dashboard_mainnavi/dashboard_tinycity_selected.png';
import dashboard_tinycity_hovered from '../../models/textures/dashboard_mainnavi/dashboard_tinycity_hovered.png';


const dashboardIcons = [
  {
    name: "configurator_normal",
    url: dashboard_config_normal
  }, {
    name: "configurator_selected",
    url: dashboard_config_selected
  }, {
    name: "configurator_hovered",
    url: dashboard_config_hovered
  }, {
    name: "dashboard_normal",
    url: dashboard_dashboard_normal
  }, {
    name: "dashboard_selected",
    url: dashboard_dashboard_selected
  }, {
    name: "dashboard_hovered",
    url: dashboard_dashboard_hovered
  }, {
    name: "explore_normal",
    url: dashboard_explorer_normal
  }, {
    name: "explore_selected",
    url: dashboard_explorer_selected
  }, {
    name: "explore_hovered",
    url: dashboard_explorer_hovered
  }, {
    name: "tinycity_normal",
    url: dashboard_tinycity_normal
  }, {
    name: "tinycity_selected",
    url: dashboard_tinycity_selected
  }, {
    name: "tinycity_hovered",
    url: dashboard_tinycity_hovered
  }
]

class MainNavigation {
  mesh = null;

  constructor(props) {
    this.store = props.store;
    this.context = props.context;
    this.router = props.router;
    this.instance = new Group();

    this.btns = [];

    this.textures = {};

    this.context.CustomTextureLoader.loadStack({
      stack: dashboardIcons,
      progress: () => { }
    }).then(textureStack => {
      this.textures = textureStack;

      //console.log("dashboardIcons", this.textures);
      this.load();
    })
  }

  load() {
    
    this.context.Loader.load({
      name: "NavBtn",
      url: NavBtnMesh
    },() => { }).then((gltf) => {

      this.mesh = gltf.scene.children[0];


      this.Init();


    }).then(()=>{

      // Load TinyCityButton
      this.context.Loader.load({
        name :  "NavTinyCityBtn",
        url : NavTinycityBtnMesh
      },()=>{}).then((model)=>{
  
        console.log("TinyCityButton", model);
  
        this.tinyCityButton = model.scene.children[0];
        //Fix flipY Issue
        this.textures.tinycity_normal.texture.flipY = false;
        this.textures.tinycity_hovered.texture.flipY = false;
        this.textures.tinycity_selected.texture.flipY = false;

        this.tinyCityButton.material = new MeshBasicMaterial({
          color: 0xffffff,
          toneMapped: false,
          map : this.textures.tinycity_normal.texture
        })
        this.context.Controls.ActiveObjects.push(this.tinyCityButton);
        //ScreenUtils.SetClickEnabled(btn, false);
        this.tinyCityButton.setState = (state) => {
          switch(state){
            case "idle":
              this.tinyCityButton.material.map = this.textures.tinycity_normal.texture;
            break;
            case "hovered":
              this.tinyCityButton.material.map = this.textures.tinycity_hovered.texture;
            break;
            case "selected":
              this.tinyCityButton.material.map = this.textures.tinycity_selected.texture;

              this.store.state.audio.controller.PlaySound("menu.click", false);
              this.router.push('/VR/tinycity').catch(()=>{});
            break;
          }
        };
  
        this.instance.add(this.tinyCityButton);
  
      });
    });
  }

  Init() {

    this.CreateButton(ViewMode.explorer, Math.PI / 3);
    this.CreateButton(ViewMode.dashboard, 0);
    this.CreateButton(ViewMode.configurator, -Math.PI / 3);

    this.router.beforeEach((to, from, next) => {
      this.ChangeState(to.name)
      next();
    });

  }

  CreateButton(name, rotation) {
    var material = new MeshBasicMaterial({
      color: 0xffffff,
      toneMapped: false
    });

    var btn = new Mesh(this.mesh.geometry.clone(), material);// this.mesh.clone();
    btn.scale.set(0.462607, 0.462607, 0.462607);
    switch (name) {
      case ViewMode.explorer:
        btn.currentTexture = this.textures.explore_normal.texture
        break;
      case ViewMode.dashboard:
        btn.currentTexture = this.textures.dashboard_normal.texture
        break;
      case ViewMode.configurator:
        btn.currentTexture = this.textures.configurator_normal.texture
        break;
    }

    btn.userData.name = name;
    material.map = btn.currentTexture;
    btn.rotation.x = rotation;
    this.instance.add(btn);

    //Make clickable
    this.context.Controls.ActiveObjects.push(btn);
    //ScreenUtils.SetClickEnabled(btn, false);
    btn.setState = (state) => this.SetState(state, name);

    this.btns.push(btn);
  }

  SetState = (state, name) => {
    if (this.router.history.current.fullPath === '/VR/' + this.router.currentRoute.params.area + '/' + name) { return; }

    var btn = this.btns.find(btn => btn.userData.name == name);

    if (state == "hovered") {
      if (typeof (btn) != "undefined") {
        this.SelectBtnTexture(name, btn, true);
      }
    } else if (state == "idle") {
      this.SelectBtnTexture(name, btn, false);
    } else if (state == "selected") {
      this.store.state.audio.controller.PlaySound("menu.click", false);
      this.router.push('/VR/'+ this.router.currentRoute.params.area + '/' + name);
    }
  }

  SelectBtnTexture(mode, btn, hovered = false) {
    //console.log(mode, btn.userData.name, this.textures[mode + "_selected"], this.textures[mode + "_normal"]);
    if (hovered) {
      btn.material.map = this.textures[mode + "_hovered"].texture;
    } else {
      btn.material.map = btn.currentTexture
    }
  }
  ChangeState = (mode) => {

    this.btns.map((btn) => {

      switch (mode) {
        case 'Explorer': mode = ViewMode.explorer; break;
        case 'ExplorerSingle': mode = ViewMode.explorer; break;
        case 'Dashboard': mode = ViewMode.dashboard; break;
        case 'Configurator': mode = ViewMode.configurator; break;
        default: break;
      }


      if (btn.userData.name == mode) {
        btn.currentTexture = this.textures[btn.userData.name + "_selected"].texture
        btn.position.x = .02;
      } else {
        btn.currentTexture = this.textures[btn.userData.name + "_normal"].texture
        btn.position.x = .01;
      }
      this.SelectBtnTexture(mode, btn);
    });

  }
}

export default MainNavigation;