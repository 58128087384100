import ThreeMeshUI from "three-mesh-ui";
import ScreenConstants from '../ScreenConstants'
import FontBoldImage from '../../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json';
import TilteSubtitle from "./TitleSubtitle";
import baterryPNG from "../../../SVG/Dashboard/akku_bg.png"
import ScreenUtils from "../ScreenUtils";
import { Battery, Shadow, Pump, Car, GreenRoof, Fascade, Area } from "../../../Enum/Enums";
import ConfigDashboardButton from "./ConfigDashboardButton";

import {TextureLoader} from 'three';

class DashboardBattery {
  constructor(props) {
    this.store = props.store;
    this.parent = props.parent;
    this.context = props.context;
    this.width = 0.15
    this.position = props.position
    this.rotation = props.rotation
    this.height = props.height
    this.padding = 0.01;
    this.load();
  }
  load() {

    this.container = new ThreeMeshUI.Block({
      width: this.width,
      height: this.height,
      backgroundColor: ScreenConstants.white,
      backgroundOpacity: 1,
      contentDirection: 'column',
      alignContent: 'left',
      margin: 0.01,
      padding: this.padding,
      offset: ScreenConstants.offset
    })

    /*
    container
      titleText
      iconcontainer
        iconcontainerinner
          progressbar
        textcontainer
          progresstext
      batterytogglebutton
        toogleButtonText

    
    */

    this.container.position.set(this.position.x, this.position.y, this.position.z);
    this.container.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
    this.parent.add(this.container)

    this.titleText = new TilteSubtitle({
      width: this.width - (this.padding),
      height: 0.025,
      container: this.container,
      titleColor: ScreenConstants.darkGrayFont,
      fontSizeSubtitle: ScreenConstants.fontMedium,
      offset: ScreenConstants.offset
    })
    this.titleText.SetTitle("Stromspeicher(5kWh)")
    //this.titleText.SetSubTitle("Der Stromspeicher versorgt Ihr Haus")



    this.iconContainer = new ThreeMeshUI.Block({
      width: this.width - (this.padding * 2),
      height: 0.08,
      backgroundOpacity: 0,
      contentDirection: "none",
      //margin: .005,
      offset: ScreenConstants.offset
    })
    this.iconContainerInner = new ThreeMeshUI.Block({
      width: this.width - (this.padding * 2),
      height: 0.08,
      contentDirection: "column",
      alignContent: 'left',
      justifyContent: "start",
      offset: ScreenConstants.offset
    })
    this.iconContainer.add(this.iconContainerInner);

    const loader = new TextureLoader();

    loader.load(baterryPNG, icon => {
      this.iconContainerInner.set({
        backgroundTexture: icon,
        backgroundSize: 'stretch'
      })
    });

    this.progressBar = new ThreeMeshUI.Block({
      width: this.width - (this.padding * 2) - .03,
      height: 0.08,
      backgroundOpacity: 0.5,
      offset: ScreenConstants.offset,
      backgroundColor: ScreenConstants.orange,
      borderRadius: 0.015,
    })
    this.progressBar.position.set(0, 0, 0)
    this.iconContainerInner.add(this.progressBar);

    this.textContainer = new ThreeMeshUI.Block({
      width: this.width - (this.padding * 2),
      height: 0.08,
      backgroundOpacity: 0,
      justifyContent: 'center',
      alignContent: 'center',
      offset: ScreenConstants.offset,
      fontSize: ScreenConstants.fontBig * .8,
      fontFamily: FontBoldJSON,
      fontTexture: FontBoldImage,
      fontColor: ScreenConstants.orange
    })

    this.textContainer.position.set(0, 0, 0);
    this.iconContainer.add(this.textContainer);

    this.progressText = new ThreeMeshUI.Text({
      content: "100%",
      offset: ScreenConstants.offset
    })
    this.textContainer.add(this.progressText)

    this.container.add(this.iconContainer)

    this.SetText();


    // No Battery Button
    this.batteryToggleButton = new ThreeMeshUI.Block({
      width: this.width - (this.padding * 4),
      margin: this.padding,
      height: .03,
      justifyContent: 'center',
      alignContent: 'center',
      fontFamily: FontBoldJSON,
      fontTexture: FontBoldImage,
      fontSize: ScreenConstants.fontMedium,
      fontColor: ScreenConstants.white,
      backgroundOpacity: 1,
      backgroundColor: ScreenConstants.blue,
      borderRadius: 0.001,
      offset: ScreenConstants.offset
    });

    this.context.Controls.ActiveObjects.push(this.batteryToggleButton);
    ScreenUtils.SetClickEnabled(this.batteryToggleButton, true);

    this.toogleButtonText = new ThreeMeshUI.Text({ content: "Anschalten", offset: ScreenConstants.offset });
    this.batteryToggleButton.add(this.toogleButtonText);

    this.batteryToggleButton.setupState({
      state: "selected",
      // attributes: selectedStateAttributes,
      onSet: () => {
        this.store.commit("area/SetBattery", this.store.state.area.battery == Battery.Battery ? Battery.NoBattery : Battery.Battery);
      }
    });

    this.batteryToggleButton.setupState({
      state: "hovered",
      attributes: {
        backgroundColor: ScreenConstants.orange
      }
    });
    this.batteryToggleButton.setupState({
      state: "idle",
      onSet: () => {
        this.store.state.audio.controller.PlaySound("menu.click", false);
        this.batteryToggleButton.set({ backgroundColor: ScreenConstants.blue, fontColor: ScreenConstants.white });
      }
    });


    this.container.add(this.batteryToggleButton);

    //Buttons
    this.configContainer = new ThreeMeshUI.Block({
      width: this.width - (this.padding * 2),
      height: 0.08,
      backgroundOpacity: 0,
      //margin: this.padding,
      contentDirection: 'column',
      offset: ScreenConstants.offset
    })

    this.carConfig = new ConfigDashboardButton({store: this.store, parent: this.configContainer, context: this.context, title: 'E-Auto', width: this.width - (this.padding * 2), storeRef: "car", trueState: Car.Car, falseState: Car.NoCar})
    this.pumpConfig = new ConfigDashboardButton({ store: this.store, parent: this.configContainer, context: this.context, title: 'Wärmepumpe', width: this.width - (this.padding * 2), storeRef: "pump", trueState: Pump.Pump, falseState: Pump.NoPump })
    this.shadowConfig = new ConfigDashboardButton({ store: this.store, parent: this.configContainer, context: this.context, title: 'Verschattung', width: this.width - (this.padding * 2), storeRef: "shadow", trueState: Shadow.Shadow, falseState: Shadow.NoShadow })
    
    this.greenRoofConfig = new ConfigDashboardButton({ store: this.store, parent: this.configContainer, context: this.context, title: 'Dachbegrünung', width: this.width - (this.padding * 2), storeRef: "greenRoof", trueState: GreenRoof.GreenRoof, falseState: GreenRoof.NoGreenRoof })
    this.fascadeConfig = new ConfigDashboardButton({ store: this.store, parent: this.configContainer, context: this.context, title: 'Fascadenmodule', width: this.width - (this.padding * 2), storeRef: "fascade", trueState: Fascade.Fascade, falseState: Fascade.NoFascade })
    this.configContainer.remove(this.greenRoofConfig.button);
    this.configContainer.remove(this.fascadeConfig.button);

    //this.configContainer.update(true,true);
    
    this.container.add(this.configContainer)


    // LIstener
    this.store.watch(state => state.area.batteryState, (newValue, oldValue) => {
      this.SetText()
    });


    this.store.watch(state => state.area.current, ()=>{
      if(this.store.state.area.current == null){return}

      console.log("Remove ConfigDashboardButtons", this.store.state.area.current.name);
      switch(this.store.state.area.current.name){
        case Area.ApartmentBuilding:
          this.configContainer.add(this.greenRoofConfig.button);
          this.configContainer.add(this.fascadeConfig.button);
        break;
        case Area.FamilyHouse:
          this.configContainer.remove(this.greenRoofConfig.button);
          this.configContainer.remove(this.fascadeConfig.button);
        break;
      }

      this.configContainer.update(true,true);
    })

    this.store.watch(state => state.area.battery, (newValue, oldValue) => {
      switch (newValue) {
        case Battery.Battery:
          this.toogleButtonText.set({
            content: "Ausschalten"
          })
          break;
        case Battery.NoBattery:
          this.toogleButtonText.set({
            content: "Anschalten"
          })
          break;
      }

      this.SetText()
    })


  }

  SetText() {

    var text = Math.round(this.store.state.area.batteryState) + "%";

    if (this.store.state.area.battery == "NoBattery") {
      text = "-"
      this.progressBar.visible = false;
    } else {
      var progress = (this.width - (this.padding * 2) - .005) * this.store.state.area.batteryState * 0.01;

      if (progress == 0) {
        this.progressBar.visible = false;
      } else {
        progress = Math.max(progress, 0.03)
        this.progressBar.visible = true;
      }
      this.progressBar.set({
        width: progress
      })

    }

    this.progressText.set({
      content: text
    })


    //this.progressBar.position.set(-this.width/2 + progress/2 + 0.002,0,0)
  }

}
export default DashboardBattery;