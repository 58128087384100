import ThreeMeshUI from "three-mesh-ui";
import ScreenConstants from '../ScreenConstants'
import FontBoldImage from '../../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json';
import ScreenUtils from "../ScreenUtils";
import ActiveButtonTex from "../../../Icon_PNG/radio_active.png";
import InactiveButtonTex from "../../../Icon_PNG/radio_passive.png";

import {TextureLoader} from 'three';

class ConfigDashboardRadioButton {
    constructor(props) {
        this.store = props.store;
        this.parent = props.parent;
        this.context = props.context;
        this.padding = props.padding;
        this.title = props.title;
        this.width = props.width;
        this.storeFunction = props.storeFunction;
        this.storeRef = props.storeRef;
        this.trueState = props.trueState;
        this.height = 0.02;
        this.button = null;
        this.iconInactive = null;
        this.iconActive = null;
        this.load();
    }
    load() {
        /*
        button
          buttonIcon
          placeholder
          textContainer
            text
        */
        this.button = new ThreeMeshUI.Block({
            width: this.width,
            height: this.height,
            backgroundOpacity: 0,
            backgroundColor: ScreenConstants.orange,
            contentDirection: 'row',
            margin: 0.0008,
            borderRadius: 0,
            justifyContent: 'center',
            offset: ScreenConstants.offset
        })

        //BUTTON ICON
        this.buttonIcon = new ThreeMeshUI.Block({
            width: 0.0057 * 3,
            height: 0.0057 * 3,
            backgroundOpacity: 1,
            borderRadius: 0.0001,
            offset: ScreenConstants.offset,
            backgroundSize: 'stretch'
        })
    
        const loader = new TextureLoader();
        loader.load(InactiveButtonTex, icon => {
            this.iconInactive = icon;
            this.buttonIcon.set({
                backgroundTexture: this.iconInactive
            })
        });
        loader.load(ActiveButtonTex, icon => {
            this.iconActive = icon;
            this.ChangeTexture();
        });

        this.buttonIcon.setupState({
            state: "selected",
            onSet: () => {
                this.store.state.audio.controller.PlaySound("menu.click", false);
                this.store.commit("area/ChangeGlobalSettings", { setting: this.storeRef, data: this.trueState });
                //this.store.commit(this.storeFunction, this.store.state.area.battery == Battery.Battery ? Battery.NoBattery : Battery.Battery);
            }
        });

        this.buttonIcon.setupState({
            state: "hovered"
        });
        this.buttonIcon.setupState({
            state: "idle"
        });

        this.store.watch(state => state.area[this.storeRef], (newValue, oldValue) => {
            this.ChangeTexture()
        })

        this.context.Controls.ActiveObjects.push(this.buttonIcon);
        ScreenUtils.SetClickEnabled(this.buttonIcon, true);
        this.button.add(this.buttonIcon)

        //Luecken fueller
        this.placeholder = new ThreeMeshUI.Block({
            width: 0.005,
            height: this.height,
            backgroundOpacity: 0,
            offset: ScreenConstants.offset
        })
        this.button.add(this.placeholder);

        //BUTTON TEXT
        this.textContainer = new ThreeMeshUI.Block({
            width: this.width - (0.0095 * 3),
            height: this.height,
            backgroundOpacity: 0,
            fontFamily: FontBoldJSON,
            fontTexture: FontBoldImage,
            fontSize: ScreenConstants.fontMedium,
            fontColor: ScreenConstants.darkGrayFont,
            offset: ScreenConstants.offset,
            alignContent: 'left',
            justifyContent: 'center'
        })
        this.text = new ThreeMeshUI.Text({ content: this.title, offset: ScreenConstants.offset, alignContent: 'left' });
        this.textContainer.add(this.text)

        this.button.add(this.textContainer)

        this.parent.add(this.button)
    }

    ChangeTexture = () => {
        if (this.store.state.area[this.storeRef] == this.trueState) {
            this.buttonIcon.set({
                backgroundTexture: this.iconActive
            })
        } else {
            this.buttonIcon.set({
                backgroundTexture: this.iconInactive
            })
        }
    }

}
export default ConfigDashboardRadioButton;