import ScreenConstants from './../ScreenConstants';
import FontBoldImage from '../../../Fonts/Roboto-Bold.png';
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json';
import ConfigButton from './ConfigButton';
import { Color } from 'three';

class ConfigSegment {
  constructor(props) {
    this.title = props.title;
    this.container = props.container;
    this.buttons = props.buttons;
    this.store = props.store;
    this.context = props.context;
    this.category = props.category;
    this.storeMutation = props.storeMutation;
    this.mesh = null;
    this.init();

  }

  init() {
    /*
    this.mesh
      titleContainer
        titleText
      buttonsContainer
        buttons
    */

    this.mesh = new ThreeMeshUI.Block({
      width: 1 - .04,
      height: 0.3,
      padding:.05,
      margin:0.02,
      backgroundOpacity: 0,
      fontColor: ScreenConstants.orange,
      backgroundColor: new Color(0, 1, 0),
      alignContent: 'left',
      offset: ScreenConstants.offset,
    });

    this.mesh.name = this.category;

    // TITLE
    const titleContainer = new ThreeMeshUI.Block({
      width: 1 - .08,
      height: 0.07,
      backgroundOpacity: 0,
      fontSize: ScreenConstants.fontBig,
      fontColor: ScreenConstants.orange,
      fontFamily: FontBoldJSON,
      fontTexture: FontBoldImage,
      alignContent: 'left',
      justifyContent: 'start',
      
    });
    const titleText = new ThreeMeshUI.Text({content: this.title,offset: ScreenConstants.offset});
    titleText.name = "title_" + this.category;
    titleContainer.add(titleText);
    this.mesh.add(titleContainer);

    // BUTTONS
    var buttonsContainer = new ThreeMeshUI.Block({
      width: 1 - .04,
      height: 0.2,
      padding:.04,
      backgroundOpacity: 0,
      fontColor: ScreenConstants.orange,
      backgroundColor: new Color(1, 1, 0),
      fontFamily: FontBoldJSON,
      fontTexture: FontBoldImage,
      alignContent: 'center',
      contentDirection: 'row',
      justifyContent: 'start',
      offset: ScreenConstants.offset,
    });

    buttonsContainer.name = "buttonsContainer";

    this.buttons.forEach(btn => {
      var button = new ConfigButton(this.store, this.context, btn, this.category, this.storeMutation);

      button.buttonContainer.name = "configButton_" + this.category;
      buttonsContainer.add(button.buttonContainer);
      var buffer = new ThreeMeshUI.Block({
        backgroundOpacity:0,
        width: 0.03,
        height: 0.22,
        offset: ScreenConstants.offset,
      });
      buttonsContainer.add(buffer)
    });
    this.mesh.add(buttonsContainer);

    //this.container.add(this.mesh);
  }
}
export default ConfigSegment
