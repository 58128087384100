import ThreeMeshUI from "three-mesh-ui";
import ScreenConstants from '../ScreenConstants'
import TilteSubtitle from "./TitleSubtitle";
import FontBoldImage from '../../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json';

import {PVSize, HouseType, OperatorConcept } from "../../../Enum/Enums";
import Utility from "../../../utils";

import ConfigDashboardRadioButton from './ConfigDashboardRadioButton';
import ScreenUtils from "../ScreenUtils";

class DashboardBeteiligung {
    constructor(props) {
        this.store = props.store
        this.parent = props.parent
        this.width = 0.17
        this.context = props.context
        this.position = props.position
        this.height = props.height
        this.rotation = props.rotation
        this.padding = .01;
        this.segmentHeight = 0.06;

        this.participation_50 = null;
        this.participation_75 = null;
        this.participation_100 = null;
        this.load();
    }
    load() {

        this.container = new ThreeMeshUI.Block({
            width: this.width,
            height: this.height,
            backgroundColor: ScreenConstants.white,
            backgroundOpacity: 1,
            contentDirection: 'column',
            alignContent: 'left',
            margin: 0.01,
            padding:this.padding
        })
        this.container.position.set(this.position.x, this.position.y, this.position.z);
        this.container.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
        this.parent.add(this.container)
        //SEASON
        this.headlineText = new TilteSubtitle({
            width: this.width - (this.padding * 2),
            height: .02,
            container: this.container,
            titleColor: ScreenConstants.orange,
            fontSizeSubtitle: ScreenConstants.fontMedium
        })
        this.headlineText.SetTitle("Betreiberkonzept");


        //Buttons
        this.configContainer = new ThreeMeshUI.Block({
          width: this.width - (this.padding * 2),
          height: 0.06,
          backgroundOpacity: 0,
          //margin: this.padding,
          contentDirection: 'column',
          offset: ScreenConstants.offset
        });
        this.container.add(this.configContainer);

        const radioButton1 = new ConfigDashboardRadioButton({store: this.store, parent: this.configContainer, context: this.context, title: 'nur Betriebsstrom', width: this.width - (this.padding * 2), storeRef: "operatorConcept", trueState: OperatorConcept.OnlyOperatorCurrent});
        const radioButton2 = new ConfigDashboardRadioButton({store: this.store, parent: this.configContainer, context: this.context, title: 'Wohnungsstrom', width: this.width - (this.padding * 2), storeRef: "operatorConcept", trueState: OperatorConcept.OperatorCurrentAndCommunity});

        
        this.beteiligungsText = new TilteSubtitle({
          width: this.width - (this.padding * 2),
          height: .02,
          container: this.container,
          titleColor: ScreenConstants.darkGrayFont,
          fontSizeSubtitle: ScreenConstants.fontMedium
      })
      this.beteiligungsText.SetTitle("Beteiligungsquote");


      this.participationRateContainer = new ThreeMeshUI.Block({
        width: this.width - (this.padding * 2),
        height: 0.08,
        backgroundOpacity: 0,
        //margin: this.padding,
        contentDirection: 'row',
        offset: ScreenConstants.offset
      });
      this.container.add(this.participationRateContainer);
      
        /** BUTTONS für ParticipationRate */
        this.participation_50  = this.CreateButton("50%" , () => {
          this.store.state.audio.controller.PlaySound("menu.click", false);
          this.store.commit("area/ChangeGlobalSettings", {
            setting : "participationRate",
            data : .5,
            boolean : null
          });
        }, (button)=>{
          if(this.store.state.area.operatorConcept == OperatorConcept.OperatorCurrentAndCommunity){
            button.set({ 
              backgroundColor: this.store.state.area.participationRate == 0.5 ? ScreenConstants.orange : ScreenConstants.blue, 
              fontColor: ScreenConstants.white 
            });
          }else{
            button.set({ 
              backgroundColor: ScreenConstants.lightGray, 
              fontColor: ScreenConstants.white 
            });
          }

        });

        this.participationRateContainer.add(this.participation_50);
        
        this.participation_75  = this.CreateButton("75%" , () => {
          this.store.state.audio.controller.PlaySound("menu.click", false);
          this.store.commit("area/ChangeGlobalSettings", {
            setting : "participationRate",
            data : .75,
            boolean : null
          });
        }, (button)=>{
          if(this.store.state.area.operatorConcept == OperatorConcept.OperatorCurrentAndCommunity){
            button.set({ 
              backgroundColor: this.store.state.area.participationRate == 0.75 ? ScreenConstants.orange : ScreenConstants.blue, 
              fontColor: ScreenConstants.white 
            });
          }else{
            button.set({ backgroundColor: ScreenConstants.lightGray, fontColor: ScreenConstants.white });
          }
        });

        this.participationRateContainer.add(this.participation_75);
        
        this.participation_100  = this.CreateButton("100%" , () => {
          this.store.state.audio.controller.PlaySound("menu.click", false);
          this.store.commit("area/ChangeGlobalSettings", {
            setting : "participationRate",
            data : 1,
            boolean : null
          });
        }, (button)=>{
          
          if(this.store.state.area.operatorConcept == OperatorConcept.OperatorCurrentAndCommunity){
            button.set({ 
              backgroundColor: this.store.state.area.participationRate == 1 ? ScreenConstants.orange : ScreenConstants.blue, 
              fontColor: ScreenConstants.white 
            });
          }else{
            button.set({ backgroundColor: ScreenConstants.lightGray, fontColor: ScreenConstants.white });
          }

        });

        this.participationRateContainer.add(this.participation_100);

        
        this.UpdateText();

        this.store.watch(state => state.area.operatorConcept, (newValue, oldValue) => {
            this.UpdateText()
        })

        this.store.watch(state => state.area.participationRate, (newValue, oldValue) => {
            this.UpdateText()
        })

    }

    CreateButton(buttonText, onClick, onIdle){
      // No Battery Button
      var button = new ThreeMeshUI.Block({
        width: (this.width - (this.padding * 4)) / 3,
        margin: this.padding / 6,
        height: .03,
        justifyContent: 'center',
        alignContent: 'center',
        fontFamily: FontBoldJSON,
        fontTexture: FontBoldImage,
        fontSize: ScreenConstants.fontMedium,
        fontColor: ScreenConstants.white,
        backgroundOpacity: 1,
        backgroundColor: ScreenConstants.blue,
        borderRadius: 0.001,
        offset: ScreenConstants.offset
      });

      this.context.Controls.ActiveObjects.push(button);
      ScreenUtils.SetClickEnabled(button, true);

      //Add Text to button
      button.add(new ThreeMeshUI.Text({ 
        content: buttonText, offset: ScreenConstants.offset 
      }));

      button.setupState({
        state: "selected",
        // attributes: selectedStateAttributes,
        onSet: onClick
      });

      button.setupState({
        state: "hovered",
        onSet : () => {
          button.set({
            backgroundColor: button.isClickEnabled ? ScreenConstants.lightOrange : ScreenConstants.darkGrayFont

          })
        }
      });
      button.setupState({
        state: "idle",
        onSet: () => {
          onIdle(button);
          
        }
      });

      return button;
    }
    UpdateText() {
      var rate = this.store.state.area.participationRate;

      if(this.store.state.area.current == null || 
          this.participation_50 == null ||
          this.participation_75 == null ||
          this.participation_100 == null){ return; }

        switch(this.store.state.area.operatorConcept){
          case OperatorConcept.OnlyOperatorCurrent:
            this.participation_50.set({
              backgroundColor: ScreenConstants.lightGray,
              fontColor : ScreenConstants.white
            });
            this.participation_75.set({
              backgroundColor: ScreenConstants.lightGray,
              fontColor : ScreenConstants.white
            });
            this.participation_100.set({
              backgroundColor: ScreenConstants.lightGray,
              fontColor : ScreenConstants.white
            });
            ScreenUtils.SetClickEnabled(this.participation_50, false);
            ScreenUtils.SetClickEnabled(this.participation_75, false);
            ScreenUtils.SetClickEnabled(this.participation_100, false);
          break;
          case OperatorConcept.OperatorCurrentAndCommunity:
            this.participation_50.set({
              backgroundColor: rate == .5 ? ScreenConstants.orange : ScreenConstants.blue,
              fontColor : ScreenConstants.white
            });
            this.participation_75.set({
              backgroundColor: rate == .75 ? ScreenConstants.orange : ScreenConstants.blue,
              fontColor : ScreenConstants.white
            });
            this.participation_100.set({
              backgroundColor: rate == 1 ? ScreenConstants.orange : ScreenConstants.blue,
              fontColor : ScreenConstants.white
            });
            ScreenUtils.SetClickEnabled(this.participation_50, true);
            ScreenUtils.SetClickEnabled(this.participation_75, true);
            ScreenUtils.SetClickEnabled(this.participation_100, true);
          break;
        }

    }

}
export default DashboardBeteiligung;