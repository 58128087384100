

import ScreenConstants from './ScreenComponents/ScreenConstants'
import {ViewMode } from '../Enum/Enums';
import ScreenUtils from './ScreenComponents/ScreenUtils'
import ConfigPanel from './ScreenComponents/Configurator/ConfigPanel';

import MenuModel from '../models/VR_UI.glb'
import ExplorerPanel from './ScreenComponents/Explorer/Explorer.js';
import DashboardPanel from './ScreenComponents/Dashboard/DashboardPanel';
import { Mesh, MeshBasicMaterial, MeshStandardMaterial, PlaneBufferGeometry,CircleGeometry, Group,TextureLoader , Object3D} from 'three';
import MainNavigation from './ScreenComponents/MainNavigation';
import VRRouter from './ScreenComponents/VRRouter';
import SoundOn from '../PNG/SoundOn.png'
import SoundOff from '../PNG/SoundOff.png'

import concrete from '../models/textures/concrete.png';

class Screen {

  constructor(context) {
    this.store = context.store;
    this.router = context.router;
    this.context = context.xr;
    this.menu = null;

    this.configPanel = null;
    this.explorerPanel = null;
    this.dashboardPanel = null;


    this.vr_router = new VRRouter(this);

    this.router.beforeEach((to, from, next) => {
      var routeName = to.name;

      console.log("VRRouter" , routeName, this.configPanel);
      this.explorerPanel?.SetVisibility(routeName == "Explorer" || routeName == "ExplorerSingle");
      this.configPanel?.SetVisibility(routeName == "Configurator");
      this.dashboardPanel?.SetVisibility(routeName == "Dashboard");

      if (this.menu != null) {
        this.menu.visible = routeName == "Explorer" || routeName == "ExplorerSingle" || routeName == "Dashboard";
      }

      if (this.navigationContainer != null) {
        this.navigationContainer.visible = routeName == "Explorer" || routeName == "ExplorerSingle" || routeName == "Dashboard";
      }

      next();
    });


    

    this.load();

  }

  load = () => {

    this.context.Loader.load({ name: "Menu", url: MenuModel},()=>{}).then((gltf) => {
      this.menu = gltf.scene.children[0];
      this.menu.name = "Menu";
      this.menu.material.color = ScreenConstants.menuColor;
      this.menu.material.toneMapped = false;
      this.menu.material.emissive = ScreenConstants.menuColor;
      //this.menu.visible = false;
      this.initMenu();
    });
  }

  initMenu = () => {
    //CONTAINER FOR MESH AND UI
    this.context.ScreenContainer = new Object3D();
    this.context.ScreenContainer.position.set(0, 1, -1.2);
    this.context.ScreenContainer.rotation.set(-0.5, 0, 0);
   
    this.context.Controls.ui_cameraHelper.add(this.context.ScreenContainer);
    //this.context.SceneController.AddToScene("UI" , this.context.ScreenContainer);

    

    this.context.CustomTextureLoader.load(concrete).then((texture)=>{

      //console.log("texture" , texture,this.vr_floor.material);
      const geometry = new CircleGeometry( 1.2, 32 );
      const material = new MeshBasicMaterial( { map : texture } );
      this.vr_floor = new Mesh( geometry, material );
      this.vr_floor.rotation.x = -90 * Math.PI / 180;
      this.vr_floor.position.y -= .75;
      //this.vr_floor.rotateX(.5);
      this.context.Controls.cameraHelper.add(this.vr_floor);

      //this.vr_floor.material.map = texture;
    })


    // this.vr_floor = new Mesh(new PlaneBufferGeometry(1000, 1000, 2, 2), new MeshStandardMaterial({
    //   color: 0x333333,
    //   envMap: this.context.Scene.sky.rt.texture
    // }));
    // this.vr_floor.receiveShadow = true;
    // this.vr_floor.rotation.x = -90 * Math.PI / 180;
    // this.vr_floor.position.y -= .2;
    
    //ADD MENU MESH TO SCENE
    //this.context.ScreenContainer.add(this.menu);
    //this.context.SceneController.AddToScene("UI_3D" , this.menu);
    this.context.Controls.ui3D_cameraHelper.attach(this.menu);

    this.menu.position.set(0, .6, -1);
    this.menu.rotation.set(-0.5, 0, 0);
    this.menu.scale.set(1.5, 1.5, 1.5);
    //this.context.ScreenContainer.add(this.menu);




    //MUTE BUTTOn
    this.muteButton = new ThreeMeshUI.Block({
      width: 0.1,
      height: 0.1,
      borderRadius: 0.02,
      backgroundOpacity: 1,
      justifyContent: 'center',
      alignContent: 'center',
      offset: ScreenConstants.offset,
      backgroundColor: ScreenConstants.white,
    });

    this.muteIconContainer = new ThreeMeshUI.Block({
      width: 0.057,
      height: 0.044,
      offset: ScreenConstants.offset,
      backgroundOpacity: 1,
      backgroundColor: ScreenConstants.white,
    })

    const loader = new TextureLoader();
    loader.load(SoundOn, icon => {
      this.soundOnIcon = icon;
      this.muteIconContainer.set({
        backgroundTexture: this.soundOnIcon
      })
    });

    loader.load(SoundOff, icon => {
      this.soundOffIcon = icon;
      this.MuteSound();
    });

    this.muteButton.add(this.muteIconContainer)
    this.muteButton.position.set(0, -0.35, 0)
    this.menu.add(this.muteButton);

    this.muteButton.setupState({
      state: "idle",
      attributes: {
        backgroundColor: ScreenConstants.white
      }
    });

    this.muteButton.setupState({
      state: "hovered",
      attributes: {
        backgroundColor: ScreenConstants.lightOrange
      }
    });

    this.muteButton.setupState({
      state: "selected",
      onSet: () => {
        this.store.state.audio.controller.PlaySound("menu.click", false);
        this.store.commit("audio/ToggleSound", !this.store.state.mute);
      }
    });

    this.context.Controls.ActiveObjects.push(this.muteButton);
    ScreenUtils.SetClickEnabled(this.muteButton, true);


    //Navigation Buttons
    //this.navigationContainer = new THREE.Group();

    this.mainNavigation = new MainNavigation({
      store: this.store,
      context: this.context,
      router: this.router
    });

    this.menu.add(this.mainNavigation.instance);
    // this.mainNavigation.instance.rotation.x = 90 * Math.PI / 180;
    this.mainNavigation.instance.rotation.y = 90 * Math.PI / 180;
    // this.mainNavigation.instance.rotation.z = Math.PI;
    this.mainNavigation.instance.scale.set(1, 1, 1)
    this.mainNavigation.instance.position.set(0, .2, 0);

    //EXPLORER CONTAINER
    this.explorerPanel = new ExplorerPanel({
      context: this.context,
      store: this.store,
      router: this.router
    });

    //CONFIGURATOR
    this.configPanel = new ConfigPanel({
      context: this.context,
      store: this.store,
      router: this.router
    });

    //Dashboard
    this.dashboardPanel = new DashboardPanel({
      context: this.context,
      store: this.store,
      router: this.router,
      container: this.context.ScreenContainer
    });

    this.OnViewChanged();


    // this.OnViewChanged();

    this.store.watch(state => state.viewMode, (newValue, oldValue) => {
      this.OnViewChanged();
    });

    this.store.watch(state => state.mute, (newValue, oldValue) => {
      this.MuteSound(newValue);
    });

    this.context.ScreenContainer.scale.set(1.5,1.5,1.5)

    if (this.store.state.world.mainScene.xr.Controls.cameraHelper.position.y > 4) {
      this.store.state.world.mainScene.xr.Controls.SetPosition(0, 1.7, 22)
    }



    

  }

  SetVisible(visible) {
    //console.log("SET Screen visible ", visible)
  }

  OnViewChanged() {
    if (this.store.state.viewMode == ViewMode.configurator || this.store.state.viewMode == ViewMode.tinyCity) {
      this.menu.visible = false;
      ScreenUtils.SetClickEnabled(this.menu, false);
    } else {
      this.menu.visible = true;
      ScreenUtils.SetClickEnabled(this.menu, true);
    }


    console.log("OnViewChanged" , this.store.state.viewMode, ViewMode.configurator);
  }

  MuteSound() {
    var tex = this.store.state.mute ? this.soundOffIcon : this.soundOnIcon;
    this.muteIconContainer.set({
      backgroundTexture: tex
    })
  }
}


export default Screen;