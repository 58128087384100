import ScreenConstants from '../ScreenConstants'
import ScreenUtils from '../ScreenUtils';
import FontBoldImage from '../../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json'
import ThreeMeshUI from 'three-mesh-ui';
import {TextureLoader} from 'three';
import MainConfig from '../../../../main.config';


class ArticleButton {
    constructor(store, context, poi, router) {
        this.store = store;
        this.context = context;
        this.poi = poi;
        this.router = router;
        //this.type = buttonInfo.type;
        // this.category = category;
        this.button = null;
        this.currentColor = ScreenConstants.white;
        this.currentFontColor = ScreenConstants.lightGrayFont;
        this.buttonWidth = 0.1;
        this.init();

    }
    init = () => {
        /*
        buttoncontainer 
                button(interactive)
                    iconContainer
                titlecontainer 
                    title (text)
         */

        this.buttonContainer = new ThreeMeshUI.Block({
            width: this.buttonWidth,
            height: this.buttonWidth + 0.05,
            justifyContent: 'center',
            alignContent: 'center',
            contentDirection: 'column',
            backgroundColor: ScreenConstants.orange,
            backgroundOpacity: 0,
            offset: ScreenConstants.offset,
        });

        const selectedStateAttributes = {
            state: "selected",
            attributes: {
                backgroundColor: ScreenConstants.orange
            }
        };

        const hoveredStateAttributes = {
            state: "hovered",
            attributes: {
                backgroundColor: ScreenConstants.lightOrange
            }
        };

        this.button = new ThreeMeshUI.Block({
            width: this.buttonWidth,
            height: this.buttonWidth,
            justifyContent: 'center',
            alignContent: 'center',
            borderRadius: ScreenConstants.borderRadius,
            backgroundOpacity: 1,
            offset: ScreenConstants.offset,
            backgroundColor: ScreenConstants.orange,
        });

        this.iconContainer = new ThreeMeshUI.Block({
            width: 0.1,
            height: 0.1,
            offset: ScreenConstants.offset,
            backgroundOpacity: 1,
            backgroundColor: ScreenConstants.white,
        })

        const loader = new TextureLoader();
        if (this.poi.IconPNG != null) {
            loader.load(MainConfig.CMS_BASE_URL + this.poi.IconPNG_inverted.url, icon => {
                this.iconInactive = icon;
                this.iconContainer.set({
                    backgroundTexture: this.iconInactive
                })
            });
        }

        if (this.poi.IconPNG_inverted != null) {
            loader.load(MainConfig.CMS_BASE_URL + this.poi.IconPNG.url, icon => {
                this.iconActive = icon;
            });
        }



        this.button.add(
            this.iconContainer
        );


        this.context.Controls.ActiveObjects.push(this.button);
        

        this.button.setupState({
            state: "selected",
            attributes: selectedStateAttributes,
            onSet: () => {
                this.store.state.audio.controller.PlaySound("menu.click", false);

                //this.store.dispatch("content/SetCurrentPOI", this.poi);
                this.router.push('/VR/'+ this.router.currentRoute.params.area  +'/explore/' + this.poi.id);
            }
        });

        this.button.setupState({
            state: "idle",
            onSet: () => {
                this.button.set({
                    backgroundColor: this.currentColor,
                    fontColor: this.currentFontColor
                })
            }
        });

        this.button.setupState(hoveredStateAttributes);
        this.buttonContainer.add(this.button);

        this.titleContainer = new ThreeMeshUI.Block({
            width: this.buttonWidth,
            height: 0.05,
            justifyContent: 'start',
            alignContent: 'center',
            backgroundOpacity: 0,
            offset: ScreenConstants.offset,
            fontFamily: FontBoldJSON,
            fontTexture: FontBoldImage,
            fontSize: ScreenConstants.fontMedium,
            fontColor: ScreenConstants.white,
            
            
        })
        this.title = new ThreeMeshUI.Text({ content: this.poi.Name, offset: ScreenConstants.offset, interLine : .001 })
        this.titleContainer.add(
            this.title
        );
        this.buttonContainer.add(this.titleContainer)

        this.store.watch(state => state.content.currentPOI, (newValue, oldValue) => {
            this.setColor();
        })

        this.store.watch(state => state.area.car, (newValue, oldValue) => {
            this.setColor();
        })
        this.store.watch(state => state.area.pump, (newValue, oldValue) => {
            this.setColor();
        })
        this.store.watch(state => state.area.shadow, (newValue, oldValue) => {
            this.setColor();
        })

        this.setColor();
    }

    setColor = () => {
        ScreenUtils.SetClickEnabled(this.button, true);
        if (this.GetIconState() == 1) {
            this.currentColor = ScreenConstants.orange
            this.currentFontColor = ScreenConstants.darkGrayFont;
            this.currentIcon = this.iconActive;
        } else if(this.GetIconState() == 0) {
            this.currentColor = ScreenConstants.white;
            this.currentFontColor = ScreenConstants.lightGrayFont;
            this.currentIcon = this.iconInactive;
        }else{
            ScreenUtils.SetClickEnabled(this.button, false);
            this.currentColor = ScreenConstants.darkGray;
            this.currentFontColor = ScreenConstants.white;
            this.currentIcon = this.iconActive;
        }

        this.button.set({
            backgroundColor: this.currentColor,
            fontColor: this.currentFontColor,

        })
        this.iconContainer.set({
            backgroundTexture: this.currentIcon
        })
    }

    GetActiveState = () => {
        var state = false;
        if (this.store.state.content.currentPOI != null) {
            state = this.poi.id == this.store.state.content.currentPOI.id;
        }
        return state;
    }

    GetIconState = () => {
        if (this.GetActiveState()) {
            return 1; //ACTIVE
        } else if (this.poi.UIBehaviour.StoreValue ==
            this.store.state.area[this.poi.UIBehaviour.StoreKey]) {
            return 0; //INACTIVE but clickable
        } else {
            return -1; //DISABLED
        }
    }

}
export default ArticleButton;