import ThreeMeshUI from "three-mesh-ui";
import ScreenConstants from '../ScreenConstants'
import TilteSubtitle from "./TitleSubtitle";
import {PVSize, HouseType, Area, Fascade } from "../../../Enum/Enums";
import Utility from "../../../utils";

class DashboardNumbers {
    constructor(props) {
        this.store = props.store
        this.parent = props.parent
        this.width = 0.17
        this.position = props.position
        this.height = props.height
        this.rotation = props.rotation
        this.padding = .01;
        this.segmentHeight = 0.06
        this.load();
    }
    load() {

        this.container = new ThreeMeshUI.Block({
            width: this.width,
            height: this.height,
            backgroundColor: ScreenConstants.white,
            backgroundOpacity: 1,
            contentDirection: 'column',
            alignContent: 'left',
            margin: 0.01,
            padding:this.padding
        })
        this.container.position.set(this.position.x, this.position.y, this.position.z);
        this.container.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
        this.parent.add(this.container)
        //SEASON
        this.seasonText = new TilteSubtitle({
            width: this.width - (this.padding * 2),
            height: this.segmentHeight,
            container: this.container,
            titleColor: ScreenConstants.orange,
            fontSizeSubtitle: ScreenConstants.fontMedium
        })
        this.seasonText.SetTitle("Größe PV-Anlage")
        this.seasonText.SetSubTitle(this.GetPVSizeText())

        

        //ERTRAG
        this.ertragText = new TilteSubtitle({
            width: this.width - (this.padding *2),
            height: this.segmentHeight,
            container: this.container,
            titleColor: ScreenConstants.orange,
            fontSizeSubtitle: ScreenConstants.fontMedium
        })
        this.ertragText.SetTitle("Aktueller Ertrag")
        this.ertragText.SetSubTitle("19.39kWh")

        //ERTRAG/TAG
        this.ertragTagText = new TilteSubtitle({
            width: this.width - (this.padding * 2),
            height: this.segmentHeight,
            container: this.container,
            titleColor: ScreenConstants.orange,
            fontSizeSubtitle: ScreenConstants.fontMedium
        })
        this.ertragTagText.SetTitle("Ertrag/Tag")
        this.ertragTagText.SetSubTitle("- 49kWh")

        this.UpdateText();

        this.store.watch(state => state.area.season, (newValue, oldValue) => {
            this.UpdateText()
        })
        
        this.store.watch(state => state.area.current, (newValue, oldValue) => {
          this.UpdateText()
        })
        
        this.store.watch(state => state.area.pvSize, (newValue, oldValue) => {
          this.UpdateText()
        })
        
        this.store.watch(state => state.area.houseType, (newValue, oldValue) => {
          this.UpdateText()
        })

        this.store.watch(state => state.area.sunPosition, (newValue, oldValue) => {
            this.UpdateText()
        })

    }

    GetPVSizeText(){
      
      if(this.store.state.area.current == null){return}

      if(this.store.state.area.current.name == Area.FamilyHouse){
        if(this.store.state.area.pvSize == PVSize.PVGross || this.store.state.area.pvSize == PVSize.PVZiegel){
          switch(this.store.state.area.houseType){
            case HouseType.Sattel:
              return "8,4 kW";
              break;
            case HouseType.Zelt:
              return "7,5 kW";
              break;
            case HouseType.Flach:
              return "7,3 kW";
              break;
          }
        }

        if(this.store.state.area.pvSize == PVSize.PVKlein){
          switch(this.store.state.area.houseType){
            case HouseType.Sattel:
              return "4,4 kW";
              break;
            case HouseType.Zelt:
              return "5 kW";
              break;
            case HouseType.Flach:
              return "4,0 kW";
              break;
          }
        }
      }
      if(this.store.state.area.current.name == Area.ApartmentBuilding){
        if(this.store.state.area.fascade == Fascade.Fascade){
          return "56,24 kWp"
        }else{
          return "26,64 kWp"
        }
      }


    }

    UpdateText() {
        
      if(this.store.state.area.current == null || this.store.state.area.current?.solarDaten == null){ return; }
        var index = Utility.CalculateSolarIndex(this.store.state.area.sunPosition);
        
        this.seasonText.SetSubTitle(this.GetPVSizeText());
        this.ertragText.SetSubTitle(this.store.state.area.current.solarDaten[index].EnergieWechselrichter.toFixed(2)+" kWh")

        var amount = 0;
        this.store.state.area.current.solarDaten.map(data => amount += data.EnergieWechselrichter);
        this.ertragTagText.SetSubTitle(Math.floor(amount)+" kWh")
    }

}
export default DashboardNumbers;