import ThreeMeshUI from "three-mesh-ui";
import ScreenConstants from '../ScreenConstants'
import FontBoldImage from '../../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json';
import TilteSubtitle from "./TitleSubtitle";
import { CanvasTexture } from "three/build/three.module";


class DashboardOverview {
  constructor(props) {
    this.store = props.store
    this.parent = props.parent
    this.width = 0.525
    this.position = props.position
    this.rotation = props.rotation
    this.height = props.height
    this.padding = 0.01;
    this.canvas = null;
    this.load();


    this.store.watch(state => state.area.season, () => this.UpdateTexture());
    this.store.watch(state => state.area.battery, () => this.UpdateTexture());
    this.store.watch(state => state.area.batteryState, () => this.UpdateTexture());
    this.store.watch(state => state.area.houseType, () => this.UpdateTexture());
    this.store.watch(state => state.area.orientation, () => this.UpdateTexture());
    this.store.watch(state => state.area.pvSize, () => this.UpdateTexture());
    this.store.watch(state => state.area.shadow, () => this.UpdateTexture());
    this.store.watch(state => state.area.sunPosition, () => this.UpdateTexture());
    this.store.watch(state => state.area.current, () => this.UpdateTexture());

    this.store.watch(state => state.area.participationRate, () => this.UpdateTexture());
    this.store.watch(state => state.area.operatorConcept, () => this.UpdateTexture());
    this.store.watch(state => state.area.greenRoof, () => this.UpdateTexture());
    this.store.watch(state => state.area.fascade, () => this.UpdateTexture());
  }

  UpdateTexture() {
    if (this.store.state.bilanzElement == null) { return; }
    if (this.store.state.viewMode != "dashboard") { return; }

    this.canvas.set({
      backgroundTexture: new CanvasTexture(this.store.state.bilanzElement)
    });
  }

  load() {

    this.container = new ThreeMeshUI.Block({
      width: this.width,
      height: this.height,
      backgroundColor: ScreenConstants.white,
      backgroundOpacity: 1,
      contentDirection: 'column',
      alignContent: 'left',
      margin: 0.01,
      padding: this.padding
    })

    this.container.position.set(this.position.x, this.position.y, this.position.z);
    this.container.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
    this.parent.add(this.container)

    this.titleText = new TilteSubtitle({
      width: this.width - (this.padding * 2),
      height: 0.025,
      container: this.container,
      titleColor: ScreenConstants.darkGrayFont,
      fontSizeSubtitle: ScreenConstants.fontMedium,
    })
    this.titleText.SetTitle("Ihr Tagesüberblick")
    //this.titleText.SetSubTitle("Sie verkaufen durchschnittlich mehr Strom also Sie einkaufen")

    //Grafik
    this.canvas = new ThreeMeshUI.Block({
      width: this.width - (this.padding * 2),
      height: 0.2,
      backgroundColor: ScreenConstants.white,
      backgroundOpacity: 1,
      backgroundSize: 'stretch',
      offset: ScreenConstants.offset
    });
    this.container.add(this.canvas)

    //legende
    // this.legendContainer = new ThreeMeshUI.Block({
    //   width: this.width,
    //   height: 0.1,
    //   backgroundOpacity: 0,
    //   contentDirection: 'column',
    //   alignContent: 'center',
    //   margin: 0.005,
    //   offset: ScreenConstants.offset,
    //   fontFamily: FontBoldJSON,
    //   fontTexture: FontBoldImage,
    //   fontSize: ScreenConstants.fontMedium,
    //   fontColor: ScreenConstants.orange,
    // })
    // this.legend = new ThreeMeshUI.Text({ content: "in kWh",offset: ScreenConstants.offset, alignContent: 'center', })
    // this.legendContainer.add(this.legend)
    // this.container.add(this.legendContainer)

    this.LoadTexture();

    this.store.watch(state => state.bilanzElement, (newValue, oldValue) => {
      this.LoadTexture();
    })
  }

  LoadTexture() {
    if (this.store.state.bilanzElement != null) {
      this.canvas.set({
        backgroundTexture: new CanvasTexture(this.store.state.bilanzElement)
      });
    }
  }

}
export default DashboardOverview;