import seasons_normal from '../../../models/wheel/seasons_normal.png';
import seasons_selected from '../../../models/wheel/seasons_selected.png';
import wheel_bg_autumn_spring from '../../../models/wheel/wheel_bg_autumn_spring.png';
import wheel_bg_summer from '../../../models/wheel/wheel_bg_summer.png';
import wheel_bg_winter from '../../../models/wheel/wheel_bg_winter.png';

import {Group} from 'three'
import DashboardWheel from './DashboardWheel';
import DashboardNumbers from './DashboardNumbers';
import DashboardBeteiligung from './DashboardBeteiligung';
import DashboardConfigFamilySettings from './DashboardConfigFamilySettings';
import DashboardOverview from './DashboardOverview';
import DashboardBattery from './DashboardBattery';

import ScreenUtils from '../ScreenUtils'
import { Area } from '@/Enum/Enums';

const textureStack = [
  {
    name : "seasons_normal",
    url :seasons_normal
  },
  {
    name : "seasons_selected",
    url : seasons_selected
  },
  {
    name : "wheel_bg_autumn_spring",
    url: wheel_bg_autumn_spring
  },
  {
    name : "wheel_bg_summer",
    url : wheel_bg_summer
  },
  {
    name : "wheel_bg_winter",
    url : wheel_bg_winter 
  }
]

class DashboardPanel {
  constructor(props) {
    this.context = props.context;
    this.store = props.store;
    this.router = props.router;
    this.parent = props.container;
    this.container = new Group();
    this.container.name = "DashboardPanel";
    this.container.position.set(0,-.285,0);
    this.container.visible = false;


    this.wheelMesh = new DashboardWheel(props);
    this.wheelMesh.instance.scale.set(.75,.75,.75);
    this.container.add(this.wheelMesh.instance);
    this.parent.add(this.container);

    var height = 0.28
    var buttonCircleRadius = 1.44;
    var startAngle = 0.42;
    var z = 0
    var y = 0

    /*var angle = Math.PI + startAngle - (startAngle * 2 / 4) * 0.11; //
    var pos = {x:buttonCircleRadius * Math.sin(angle), y: y, z:z+ buttonCircleRadius * Math.cos(angle) + buttonCircleRadius}
    var rot = {x:0, y:angle + Math.PI, z:0}
    this.dashboardText = new DashboardText({store:this.store, parent: this.container, position:pos, rotation:rot, height:height})*/
    // var angle = Math.PI + startAngle - (startAngle * 2 / 4) * -0.12; //
    // var pos = {x:buttonCircleRadius * Math.sin(angle), y: y, z:z+ buttonCircleRadius * Math.cos(angle) + buttonCircleRadius}
    // var rot = {x:0, y:angle + Math.PI+ 0.02, z:0}
    //this.dashboardOverview = new DashboardLegende ({store:this.store, parent: this.container, position:pos, rotation:rot, height:height})


    var angle = Math.PI + startAngle - (startAngle * 2 / 4) * 0.48; //
    var pos = {x:buttonCircleRadius * Math.sin(angle), y: y, z:z+ buttonCircleRadius * Math.cos(angle) + buttonCircleRadius}
    var rot = {x:0, y:angle + Math.PI + 0.02, z:0}
    this.dashboardOverview = new DashboardOverview ({store:this.store, parent: this.container, position:pos, rotation:rot, height:height})

   
    angle = Math.PI + startAngle - (startAngle * 2 / 4) * 2.9; //
    pos = {x:buttonCircleRadius * Math.sin(angle), y: y, z:z+ buttonCircleRadius * Math.cos(angle) + buttonCircleRadius}
    rot = {x:0, y:angle + Math.PI- 0.02, z:0}
    this.dashboardNumbers= new DashboardNumbers({store:this.store, parent: this.container, position:pos, rotation:rot, height:height})
   
    
    angle = Math.PI + startAngle - (startAngle * 2 / 4) * 3.5225; //
    pos = {x:buttonCircleRadius * Math.sin(angle), y: y, z:z+ buttonCircleRadius * Math.cos(angle) + buttonCircleRadius}
    rot = {x:0, y:angle + Math.PI- 0.02, z:0}
    this.dashboardBeteiligung = new DashboardBeteiligung({store:this.store,context : this.context, parent: this.container, position:pos, rotation:rot, height:height})
    this.dashboardConfigFamilySettings = new DashboardConfigFamilySettings({store:this.store,context : this.context, parent: this.container, position:pos, rotation:rot, height:height})
   
    

    angle = Math.PI + startAngle - (startAngle * 2 / 4) * 4.1; //
    pos = {x:buttonCircleRadius * Math.sin(angle), y: y, z:z+ buttonCircleRadius * Math.cos(angle) + buttonCircleRadius}
    rot = {x:0, y:angle + Math.PI- 0.02, z:0}
    this.dashboardBattery = new DashboardBattery({
      store:this.store, 
      parent: this.container, 
      context : this.context,
      position:pos, 
      rotation:rot, 
      height:height,
    })

    this.SetVisibility(false);


    this.store.watch(state => state.area.current, (newArea)=>{
      if(newArea == null){return}

      console.log("Update DashboardBeteiligung");
      
      this.ChangeDashboardBeteiligung()
    })
  }

  ChangeDashboardBeteiligung(){

    if(this.store.state.area.current.name == Area.ApartmentBuilding){
      this.container.add(this.dashboardBeteiligung.container);
      ScreenUtils.SetClickEnabled(this.dashboardBeteiligung.container, true);

      this.container.remove(this.dashboardConfigFamilySettings.container);
      ScreenUtils.SetClickEnabled(this.dashboardConfigFamilySettings.container, false);
    }else{
      this.container.remove(this.dashboardBeteiligung.container);
      ScreenUtils.SetClickEnabled(this.dashboardBeteiligung.container, false);


      this.container.add(this.dashboardConfigFamilySettings.container);
      ScreenUtils.SetClickEnabled(this.dashboardConfigFamilySettings.container, true);
    }

    //this.container.update(true,true);
  }
  
  SetVisibility = (boolean) => {
    if (this.container != null) {
      this.container.visible = boolean;

      //this.ChangeDashboardBeteiligung()
      ScreenUtils.SetClickEnabled(this.container, boolean);
    }

  }
}

export default DashboardPanel;