import ScreenConstants from './../ScreenConstants'
import ScreenUtils from './../ScreenUtils';
//Dacharten
import Icon_Satteldach_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Satteldach.png';
import Icon_Satteldach_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Satteldach.png';
import Icon_Zeltdach_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Zeltdach.png';
import Icon_Zeltdach_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Zeltdach.png';
import Icon_Flachdach_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Flachdach.png';
import Icon_Flachdach_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Flachdach.png';
//PVAnlagen
import Icon_PVGross_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_PVGross.png';
import Icon_PVGross_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_PVGross.png';
import Icon_PVKlein_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_PVKlein.png';
import Icon_PVKlein_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_PVKlein.png';
import Icon_Solarziegel_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Solarziegel.png';
import Icon_Solarziegel_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Solarziegel.png';
//Car
import Icon_EAutoTrue_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_EAutoTrue.png';
import Icon_EAutoTrue_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_EAutoTrue.png';
import Icon_EAutoFalse_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_EAutoFalse.png';
import Icon_EAutoFalse_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_EAutoFalse.png';
//Shadow
import Icon_VerschattungTrue_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_VerschattungTrue.png';
import Icon_VerschattungTrue_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_VerschattungTrue.png';
import Icon_VerschattungFalse_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_VerschattungFalse.png';
import Icon_VerschattungFalse_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_VerschattungFalse.png';
//Pump
import Icon_PumpTrue_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_PumpTrue.png';
import Icon_PumpTrue_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_PumpTrue.png';
import Icon_PumpFalse_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_PumpFalse.png';
import Icon_PumpFalse_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_PumpFalse.png';

//Fascade
import Icon_FascadeTrue_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Fassadenmodule.png';
import Icon_FascadeTrue_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Fassadenmodule.png';
import Icon_FascadeFalse_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_KeineFassadenmodule.png';
import Icon_FascadeFalse_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_KeineFassadenmodule.png';

//Fascade
import Icon_operatorConceptTrue_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Betriebsstrom.png';
import Icon_operatorConceptTrue_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Betriebsstrom.png';
import Icon_operatorConceptFalse_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Mieterstrom.png';
import Icon_operatorConceptFalse_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Mieterstrom.png';

//GreenRoof
import Icon_greenRoofTrue_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Dachbegrünung.png';
import Icon_greenRoofTrue_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Dachbegrünung.png';
import Icon_greenRoofFalse_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_KeineDachbegrünung.png';
import Icon_greenRoofFalse_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_KeineDachbegrünung.png';






import {
  HouseType,
  PVSize,
  Shadow,
  Car,
  Pump,
  Fascade,
  GreenRoof,
  OperatorConcept,
  Battery,
  Area,
} from '../../../Enum/Enums';

import FontBoldImage from '../../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json'
import ConfigSegment from './ConfigSegment';


class ConfigPanel {
  constructor(props) {
    this.context = props.context;
    this.store = props.store;
    this.router = props.router;
    this.mesh = null;
    this.padding = .05;


    this.store.watch(state => state.content.currentViewContent, (content)=>{
      if(content != null && this.router.currentRoute.name == "Configurator" && this.mesh == null){
        this.load();

        console.log("CurrentRoute is Configurator");
      }
    })
    
    this.store.watch(state => state.area.current, ()=>{
      if(this.router.currentRoute.name == "Configurator" && this.mesh == null && this.store.state.area.current != null){
        this.SetButtonsForArea();
      }
    })
    
    this.store.watch(state => state.viewMode, ()=>{
      if(this.store.state.viewMode != "configurator"){
        this.SetVisibility(false);
      }
    })

  }
  
  load() {
    
    this.init();
    
    this.store.state.audio.controller.PlaySound("season.Summer", true);
  }

  init() {
    /*
    mesh
      titlecontainer
        titletext
      buttoncontainer
        leftbuttoncontainer
        rightbuttoncontainer
      submitcontainer
     */
    const configContainerSettings = {
      contentDirection: 'column',
      justifyContent: 'start',
      height: 1.55,
      width: 2,
      backgroundOpacity: 1,
      offset: ScreenConstants.offset,
      backgroundColor: ScreenConstants.lightGray,
      fontFamily: FontBoldJSON,
      fontTexture: FontBoldImage,
      padding: this.padding
    }
    this.mesh = new ThreeMeshUI.Block(configContainerSettings);
    this.mesh.name = "ConfigPanel";

    this.mesh.position.set(0, 0, 0);
    this.mesh.scale.set(.5,.5,.5);
    //this.context.ScreenContainer.add(this.mesh);

    // TITLE
    const titleConfig = {
      width: 1 - this.padding,
      height: 0.05,
      backgroundOpacity: 0,
      fontSize: ScreenConstants.fontBig,
      fontColor: ScreenConstants.darkGrayFont,
      offset: ScreenConstants.offset,
      contentDirection: 'column',
      justifyContent: 'start'
    }

    const titleContainer = new ThreeMeshUI.Block(titleConfig);
    const titleText = new ThreeMeshUI.Text({content: this.store.state.content.currentViewContent.Title})
    titleContainer.add(titleText);
    this.mesh.add(titleContainer);


    // BUTTONS (LEFT and RIGHT Cols)
    const buttonsContainerSettings = {
      contentDirection: 'row',
      justifyContent: 'start',
      height: 1.1,
      width: 2 - (this.padding * 2),
      backgroundOpacity: 0,
      offset: ScreenConstants.offset,
      fontFamily: FontBoldJSON,
      fontTexture: FontBoldImage
    }
    this.buttonContainer = new ThreeMeshUI.Block(buttonsContainerSettings);

    const buttonsSubContainerSettings = {
      // contentDirection: 'column',
      // justifyContent: 'start',
      height: 1.1,
      width: 1 - this.padding,
      backgroundOpacity: 0,
      offset: ScreenConstants.offset,
      fontFamily: FontBoldJSON,
      fontTexture: FontBoldImage
    }
    this.leftButtonSubContainer = new ThreeMeshUI.Block(buttonsSubContainerSettings);
    this.leftButtonSubContainer.name = "leftButtonSubContainer";
    this.rightButtonSubContainer = new ThreeMeshUI.Block(buttonsSubContainerSettings);
    this.rightButtonSubContainer.name = "rightButtonSubContainer";
    this.buttonContainer.add(this.leftButtonSubContainer, this.rightButtonSubContainer);
    this.mesh.add(this.buttonContainer)


    this.configSegments = {

      // HouseType
      houseSegment : new ConfigSegment({
        title: this.store.state.content.currentViewContent.HouseTitle,
        container: this.leftButtonSubContainer,
        buttons: [
          {
            name: "Satteldach",
            type: HouseType.Sattel,
            icon_active: Icon_Satteldach_A,
            icon_inactive: Icon_Satteldach_P,
          }, {
            name: "Zeltdach",
            type: HouseType.Zelt,
            icon_active: Icon_Zeltdach_A,
            icon_inactive: Icon_Zeltdach_P
          }, {
            name: "Flach",
            type: HouseType.Flach,
            icon_active: Icon_Flachdach_A,
            icon_inactive: Icon_Flachdach_P,
            constraint: PVSize.PVZiegel
          }
        ],
        category: "houseType",
        store: this.store,
        context: this.context
      }),
      // PVSize
      pvSegment : new ConfigSegment({
        title: this.store.state.content.currentViewContent.PVTitle,
        container: this.leftButtonSubContainer,
        buttons: [
          {
            name: "Grosse PV-Anlage",
            type: PVSize.PVGross,
            icon_active: Icon_PVGross_A,
            icon_inactive: Icon_PVGross_P
          }, {
            name: "Kleine PV-Anlage",
            type: PVSize.PVKlein,
            icon_active: Icon_PVKlein_A,
            icon_inactive: Icon_PVKlein_P
          }, {
            name: "PV Ziegel",
            type: PVSize.PVZiegel,
            icon_active: Icon_Solarziegel_A,
            icon_inactive: Icon_Solarziegel_P,
            constraint: HouseType.Flach
          }
        ],
        category: "pvSize",
        store: this.store,
        context: this.context
      }),
      // Car
      carSegment : new ConfigSegment({
        title: this.store.state.content.currentViewContent.CarTitle,
        container: this.leftButtonSubContainer,
        buttons: [
          {
            name: "E-Auto",
            type: Car.Car,
            icon_active: Icon_EAutoTrue_A,
            icon_inactive: Icon_EAutoTrue_P
          }, {
            name: "Kein E-Auto",
            type: Car.NoCar,
            icon_active: Icon_EAutoFalse_A,
            icon_inactive: Icon_EAutoFalse_P
          }
        ],
        category: "car",
        store: this.store,
        context: this.context
      }),
      // Shadow
      shadowSegment : new ConfigSegment({
        title: this.store.state.content.currentViewContent.ShadowTitle,
        container: this.rightButtonSubContainer,
        buttons: [
          {
            name: "Verschattung",
            type: Shadow.Shadow,
            icon_active: Icon_VerschattungTrue_A,
            icon_inactive: Icon_VerschattungTrue_P
          }, {
            name: "Keine/Wenig Verschattung",
            type: Shadow.NoShadow,
            icon_active: Icon_VerschattungFalse_A,
            icon_inactive: Icon_VerschattungFalse_P
          }
        ],
        category: "shadow",
        store: this.store,
        context: this.context
      }),
      // Pump
      pumpSegment : new ConfigSegment({
        title: this.store.state.content.currentViewContent.PumpTitle,
        container: this.rightButtonSubContainer,
        buttons: [
          {
            name: "Wärmepumpe",
            type: Pump.Pump,
            icon_active: Icon_PumpTrue_A,
            icon_inactive: Icon_PumpTrue_P
          }, {
            name: "Kein Wärmepumpe",
            type: Pump.NoPump,
            icon_active: Icon_PumpFalse_A,
            icon_inactive: Icon_PumpFalse_P
          }
        ],
        category: "pump",
        store: this.store,
        context: this.context
      }),
      // Pump
      batterySegment : new ConfigSegment({
        title: this.store.state.content.currentViewContent.BatteryTitle,
        container: this.rightButtonSubContainer,
        buttons: [
          {
            name: "Stromspeicher",
            type: Battery.Battery,
            icon_active: Icon_PumpTrue_A,
            icon_inactive: Icon_PumpTrue_P
          }, {
            name: "Kein Stromspeicher",
            type: Battery.NoBattery,
            icon_active: Icon_PumpFalse_A,
            icon_inactive: Icon_PumpFalse_P
          }
        ],
        category: "battery",
        store: this.store,
        context: this.context
      }),
      // OperatorConcept
      operatorConceptSegment : new ConfigSegment({
        title: this.store.state.content.currentViewContent.OperatorConceptTitle,
        container: this.leftButtonSubContainer,
        buttons: [
          {
            name: "Betriebsstrom",
            type: OperatorConcept.OnlyOperatorCurrent,
            icon_active: Icon_operatorConceptTrue_A,
            icon_inactive: Icon_operatorConceptTrue_P
          }, {
            name: "Betriebs- und Mieterstrom",
            type: OperatorConcept.OperatorCurrentAndCommunity,
            icon_active: Icon_operatorConceptFalse_A,
            icon_inactive: Icon_operatorConceptFalse_P
          }
        ],
        category: "operatorConcept",
        store: this.store,
        context: this.context
      }),
      // Fascade
      fascadeSegment : new ConfigSegment({
        title: this.store.state.content.currentViewContent.FascadeTitle,
        container: this.leftButtonSubContainer,
        buttons: [
          {
            name: "Fascadenmodule",
            type: Fascade.Fascade,
            icon_active: Icon_FascadeTrue_A,
            icon_inactive: Icon_FascadeTrue_P
          }, {
            name: "Keine Fascadenmodule",
            type: Fascade.NoFascade,
            icon_active: Icon_FascadeFalse_A,
            icon_inactive: Icon_FascadeFalse_P
          }
        ],
        category: "fascade",
        store: this.store,
        context: this.context
      }),
      
      // GreenRoof
      greenRoofSegment : new ConfigSegment({
        title: this.store.state.content.currentViewContent.GreenRoofTitle,
        container: this.leftButtonSubContainer,
        buttons: [
          {
            name: "Dachbegrünung",
            type: GreenRoof.GreenRoof,
            icon_active: Icon_greenRoofTrue_A,
            icon_inactive: Icon_greenRoofTrue_P
          }, {
            name: "Keine Dachbegrünung",
            type: GreenRoof.NoGreenRoof,
            icon_active: Icon_greenRoofFalse_A,
            icon_inactive: Icon_greenRoofFalse_P
          }
        ],
        category: "greenRoof",
        store: this.store,
        context: this.context
      })
      
      
    }

    // SubmitButton

    const submitContainer = new ThreeMeshUI.Block({
      contentDirection: 'row',
      justifyContent: 'center',
      height: 0.2,
      width: 2 - (this.padding * 2),
      backgroundOpacity: 0,
      offset: ScreenConstants.offset,
      fontFamily: FontBoldJSON,
      fontTexture: FontBoldImage
    });
    this.mesh.add(submitContainer);


    var submitButton = new ThreeMeshUI.Block({
      width: 1.2,
      height: 0.18,
      justifyContent: 'center',
      alignContent: 'center',
      borderRadius: 0,
      fontSize: ScreenConstants.fontBig,
      fontColor: ScreenConstants.white,
      backgroundOpacity: 1,
      margin: 0.02,
      offset: ScreenConstants.offset,
      backgroundColor: ScreenConstants.orange
    });

    this.context.Controls.ActiveObjects.push(submitButton);
    //ScreenUtils.SetClickEnabled(submitButton, true);

    var submitTitle = new ThreeMeshUI.Text({content: "Weiter"})

    submitButton.add(submitTitle);

    submitButton.setupState({
      state: "selected",
      // attributes: selectedStateAttributes,
      onSet: () => {
        submitButton.set({backgroundColor: ScreenConstants.white, fontColor: ScreenConstants.orange});

        this.store.state.audio.controller.PlaySound("menu.click", false);

        this.store.state.audio.controller.PlaySound("season.Summer", true);

        console.log("clicked");

        this.router.push("/VR/"+ this.router.currentRoute.params.area +"/dashboard");
        this.SetVisibility(false);
        // this.store.commit("area/ChangeGlobalSettings",{setting: this.category, data: this.type});
      }
    });

    submitButton.setupState({
      state: "hovered",
      attributes: {
        backgroundColor: ScreenConstants.lightOrange
      }
    });
    submitButton.setupState({
      state: "idle",
      onSet: () => {
        submitButton.set({backgroundColor: ScreenConstants.orange, fontColor: ScreenConstants.white});
      }
    });


    submitContainer.add(submitButton);


    this.SetVisibility(true);
  }

  SetButtonsForArea(){
    if(this.mesh == null || this.store.state.area.current == null){return;}
    ScreenUtils.SetClickEnabled(this.mesh, false);
    
    var optionsToShow = [];
    console.log("Added option " , this.router.currentRoute, this.router.currentRoute.params.area);
      switch(this.store.state.area.current.name){
        case Area.FamilyHouse:
          optionsToShow.push("houseSegment" , "pvSegment", "carSegment", "shadowSegment", "pumpSegment", "batterySegment")
        break;
        case Area.ApartmentBuilding:
          optionsToShow.push("operatorConceptSegment", "greenRoofSegment", "fascadeSegment", "pumpSegment", "batterySegment")
        break;
      }

      //Remove all Buttons first => fix für layout
      Object.keys(this.configSegments).map((option)=>{
        this.configSegments[option].container.remove(this.configSegments[option].mesh);
      });  
      this.buttonContainer.update(true,true);
      //Füge die nötigen Buttons wieder hinzu
      Object.keys(this.configSegments).map((option)=>{
        if(optionsToShow.includes(option)){
          this.configSegments[option].container.add(this.configSegments[option].mesh);
          console.log("Added option " , option, this.configSegments[option], this.configSegments[option].mesh, this.container);
          ScreenUtils.SetClickEnabled(this.configSegments[option].mesh, true);
        }else{
          this.configSegments[option].container.remove(this.configSegments[option].mesh);
          ScreenUtils.SetClickEnabled(this.configSegments[option].mesh, false);
        }
      })
      

      this.buttonContainer.update(true,true);
      console.log(this.configSegments);
  }

  SetVisibility = (boolean) => {
    if(this.mesh == null){return;}

    this.SetButtonsForArea();

    this.mesh.visible = boolean;

    if(boolean){
      this.context.ScreenContainer.add(this.mesh);
    }else{
      this.context.ScreenContainer.remove(this.mesh);
    }

    ScreenUtils.SetClickEnabled(this.mesh, boolean);
  }


}

export default ConfigPanel
