import ScreenUtils from '../ScreenUtils'
import {Group } from 'three';

import ArticleButton from './ArticleButton';
import Article from './Article';
import SubTabButton from './SubTabButton';


class ExplorerPanel {
    constructor(props) {
        this.context = props.context;
        this.store = props.store;
        this.mesh = null;
        this.router = props.router;
        this.pageContent = null;
        this.currentSubtabs = [];
        this.load();

        this.buttons = {};

    }

    load() {
        ScreenUtils.LoadPageContent("/ui-section-explorer").then((content) => {
            this.pageContent = content.data;
            this.init();
        })
    }


    init = () => {

        /*
        articleBtnContainer
            subTabContainer
            playBtnContainer
        */
        this.articleBtnContainer = new Group();
        this.articleBtnContainer.name = "ArticleBtnContainer";
        this.articleBtnContainer.position.set(0,-.295,0);

        this.context.ScreenContainer.add(this.articleBtnContainer);

        var buttonCircleRadius = 1.5;
        var count = this.store.state.content.poi.length - 1;
        var startAngle = 0.4;

        //Article Buttons
        var row = 0.08;
        var rowIDIndex = 0;
        var rowCount = 10;
        this.store.state.content.poi.forEach((poi, idx) => {

          if(!Object.prototype.hasOwnProperty.call( this.buttons, poi.Room.SceneRoom) ){
            this.buttons[poi.Room.SceneRoom] = [];
          }

          console.log("ArticleButtons" ,  poi.Room.SceneRoom );

          if(idx % rowCount == 0 && idx != 0){
            row -= .15;
            rowIDIndex = 0;
          }

          const btn = new ArticleButton(this.store, this.context, poi, this.router).buttonContainer;
          var angle = Math.PI + startAngle - (startAngle * 2 / rowCount) * rowIDIndex;

          btn.position.set(buttonCircleRadius * Math.sin(angle) , row, buttonCircleRadius * Math.cos(angle) + buttonCircleRadius);
          
          btn.rotation.set(0, angle + Math.PI, 0)
          //this.articleBtnContainer.add(btn);

          this.buttons[poi.Room.SceneRoom].push(btn);

          rowIDIndex++;
        });

        this.subTabContainer = new Group();
        this.articleBtnContainer.add(this.subTabContainer);


        console.log(this.buttons);


        //Article
        this.article = new Article({
            context: this.context,
            store: this.store,
            container: this.articleBtnContainer,
            poi: this.store.state.content.currentPOI
        })

        this.store.watch(state => state.content.currentPOI, (newValue, oldValue) => {
            this.SetSubTabs();
        })
        //this.SetSubTabs();
        this.SetVisibility(false);

    }

    SetSubTabs() {
        for (var i = this.currentSubtabs.length - 1; i >= 0; i--) {
            var tab = this.currentSubtabs[i];
            ScreenUtils.SetClickEnabled(tab.button, false);
            this.subTabContainer.remove(tab.buttonContainer);
            delete this.currentSubtabs[i];
        }
        this.currentSubtabs = [];

        this.GetVisibleSubTabs().forEach((subTab, idx) => {
            var subtab = new SubTabButton({ store: this.store, context: this.context, subTab: subTab });
            var btn = subtab.buttonContainer
            btn.position.set(0.11 * idx - 0.23, -0.2, 0.01)
            this.subTabContainer.add(btn)
            this.currentSubtabs.push(subtab)
        });
        this.SetFirstSubTab();
        this.subTabContainer.visible = this.GetVisibleSubTabs().length > 1;

    }

    SetFirstSubTab() {
        if (this.store.state.content.currentPOI == null) {
            return
        }
        if (
            this.store.state.content.currentPOI != null &&
            this.store.state.content.currentPOI.SubTabs != null
        ) {
            if (this.GetVisibleSubTabs().length > 0) {
                this.store.commit(
                    "SetSubTab",
                    this.GetVisibleSubTabs()[0]
                );
                return;
            }
        }
        this.store.dispatch("content/SetSubTab", null);
    }

    CheckTabState(tab) {
        if (!tab.ConfiguratorDependent) {
            return true;
        }
        //Konfigurator anhängig aber den richtigen Konfigurator Einstellungen entsprechen
        var matchingRefs = 0;


        tab.StoreReference.forEach((ref) => {
            var groups = ref.Value.split(".");
            groups = groups.sort().join();

            if (groups.includes(this.store.state.area[ref.Key])) {
                matchingRefs++;
            }
        });
        return (matchingRefs == tab.StoreReference.length);
    }

    GetVisibleSubTabs() {
        if (this.store.state.content.currentPOI == null) {
            return []
        }

        return this.store.state.content.currentPOI.SubTabs.filter((tab) => {
            var state = false;
            var visible = this.CheckTabState(tab)
            if (visible) {
                state = true;
            } else {
                state = !tab.CompletelyDisabled;
            }
            return state;
            //Inaktive Tab untscheiden zwischen unsichtbar und ausgegraut
        });
    }


    RePositionButton(btn, index,row, rowIDIndex){
      
      var rowCount = 10;
      var startAngle = 0.4;
      var buttonCircleRadius = 1.5;

      if(index % rowCount == 0 && index != 0){
        row -= .15;
        rowIDIndex = 0;
      }

      var angle = Math.PI + startAngle - (startAngle * 2 / rowCount) * rowIDIndex;

      btn.position.set(buttonCircleRadius * Math.sin(angle) , row, buttonCircleRadius * Math.cos(angle) + buttonCircleRadius);
      btn.rotation.set(0, angle + Math.PI, 0);
      return {row : row , rowIDIndex :(rowIDIndex + 1)};
    }


    SetVisibility(visible) {
      this.articleBtnContainer.visible = visible;

      if(visible){
        console.log("SetVisiblity Explorer" , this.buttons, this.store.state.area.current.rooms);
        let visiblePointButtons = [];
        Object.keys(this.buttons).map((roomName)=>{
          if(this.store.state.area.current.rooms.includes(roomName)){
            this.buttons[roomName].map((btn)=>{
              this.articleBtnContainer.add(btn);
              visiblePointButtons.push(btn);
            });

          }else{
            this.buttons[roomName].map((btn)=>{
              this.articleBtnContainer.remove(btn);
            });
          }
        });

        var rowIDIndex = 0;
        var row = 0.08;
        visiblePointButtons.map((btn,index)=>{
          let rowInfo = this.RePositionButton(btn,index,row, rowIDIndex);
          row = rowInfo.row;
          rowIDIndex = rowInfo.rowIDIndex;
        })



      }

      ScreenUtils.SetClickEnabled(this.articleBtnContainer, visible);
    }


}
export default ExplorerPanel