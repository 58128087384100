import ThreeMeshUI from "three-mesh-ui";
import ScreenConstants from '../ScreenConstants'
import FontBoldImage from '../../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json';
class TilteSubtitle {

    constructor(props) {
        this.width = props.width
        this.height = props.height
        this.container = props.container
        this.titleColor = props.titleColor
        this.titleHeight = 0.025
        this.load();

    }

    load() {

        //TITLE 
        this.titleContainer = new ThreeMeshUI.Block({
          width: this.width,
          height: this.titleHeight,
          backgroundOpacity: 0,
          offset: ScreenConstants.offset,
          fontSize: ScreenConstants.fontDashboard,
          fontFamily: FontBoldJSON,
          fontTexture: FontBoldImage,
          fontColor: this.titleColor ,
          alignContent: 'left'
        })
        this.container.add(this.titleContainer)

        this.title = new ThreeMeshUI.Text({
            content: "",
            offset: ScreenConstants.offset
        })
        this.titleContainer.add(this.title)

        //Subtitle
        this.subtitleContainer = new ThreeMeshUI.Block({
            width: this.width,
            height: this.height-this.titleHeight,
            backgroundOpacity: 0,
            offset: ScreenConstants.offset,
            fontSize:  ScreenConstants.fontDashboard,
            fontFamily: FontBoldJSON,
            fontTexture: FontBoldImage,
            fontColor: ScreenConstants.darkGrayFont,
            alignContent: 'left',
            interLine: ScreenConstants.interLine

        })
        this.container.add(this.subtitleContainer)

        this.subtitle = new ThreeMeshUI.Text({
            content: "",
            offset: ScreenConstants.offset
        })
        this.subtitleContainer.add(this.subtitle)
    }

    SetTitle(title){
        this.title.set({content:title})
    }

    SetSubTitle(subtitle){
        this.subtitle.set({content:subtitle})
    }
}

export default TilteSubtitle