import { Season } from '@/Enum/Enums';
import {Group, Object3D} from 'three';


class SeasonController {

    Store: any;
    Xr: any;
    SeasonModels: {
        [Name: string]: Array<Object3D>
    }

    constructor(props: any) {
        this.Store = props.store;
        this.Xr = props.xr;
        this.SeasonModels = {
            "Spring": [],
            "Summer": [],
            "Autumn": [],
            "Winter": []
        }
        this.Init();
    }
    Init() {
        this.Store.watch(state => state.area.season, this.HandleConfigChange);


    }

    HandleConfigChange=(newVal, oldVal) =>{

        if (oldVal != null) {
            this.SeasonModels[oldVal].forEach(model => {
                model.visible = false;
            })
        }



        this.SeasonModels[newVal].forEach(model => {
            model.visible = true;
        })
    }

    AddSeasonObjects = (stack: Group) => {
        Object.values(stack).map((stackObj:any) => {
            stackObj.scene.traverse((child) => {
                if (child.userData?.SeasonMesh != null) {
                    var groups = child.userData.SeasonMesh.split(", ")
                    groups.forEach(group => {
                        this.SeasonModels[group].push(child);
                        child.visible = false;
                    });
                }
            });
        });

        this.HandleConfigChange(this.Store.state.area.season, null);
    }
}

export default SeasonController