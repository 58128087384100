import Wheel from '../../../models/wheel/season_time_wheel.glb';
import seasons_normal from '../../../models/wheel/seasons_normal.png';
import seasons_selected from '../../../models/wheel/seasons_selected.png';
import wheel_bg_autumn_spring from '../../../models/wheel/wheel_bg_autumn_spring.png';
import wheel_bg_summer from '../../../models/wheel/wheel_bg_summer.png';
import wheel_bg_winter from '../../../models/wheel/wheel_bg_winter.png';
import TWEEN from '@tweenjs/tween.js';
import { Color, Group, Mesh, MeshBasicMaterial, SphereGeometry } from 'three';
import Utility from '../../../utils';

const textureStack = [
  {
    name : "seasons_normal",
    url :seasons_normal
  },
  {
    name : "seasons_selected",
    url : seasons_selected
  },
  {
    name : "wheel_bg_autumn_spring",
    url: wheel_bg_autumn_spring
  },
  {
    name : "wheel_bg_summer",
    url : wheel_bg_summer
  },
  {
    name : "wheel_bg_winter",
    url : wheel_bg_winter 
  }
]

class DashboardWheel {
  constructor(props) {
    this.context = props.context;
    this.store = props.store;
    this.router = props.router;
    
    this.sunColor = new Color(0xf38d2f);
    this.moonColor = new Color(0xcccccc);

    this.instance = new Group();
    this.instance.position.y -= 0.03
    
    this.indicatorRotator = new Group();
    this.instance.add(this.indicatorRotator);
    this.indicatorRotator.position.set(0,0,.01);
        this.indicator = new Mesh(new SphereGeometry(.04,32,32), new MeshBasicMaterial({
          color : this.sunColor,
          toneMapped : false
        }));
    this.indicatorRotator.add(this.indicator);
    this.indicator.position.set(0,.18,0);


    this.dashboardReady = false;
    this.wheelMesh = null;
    this.hoverTimeColor = new Color(0xcccccc);
    this.hoverSeasonColor = new Color(0x303448);
    this.idleColor = new Color(0xffffff);
    this.textures = {
      current : {
        season: null,
        whee : null
      },
      seasons : {
        normal : null,
        selected : null
      },
      wheel : {
        spring : null,
        summer : null,
        autumn : null,
        winter : null,
      }
    }
    this.load();

    this.initListener();

  }
  load() {
      this.context.CustomTextureLoader.loadStack({
        stack : textureStack,
      progress : ()=>{}
    }).then(loadedStack =>{
      
      this.textures.seasons.normal = loadedStack.seasons_normal.texture;
      this.textures.seasons.selected = loadedStack.seasons_selected.texture;
      this.textures.wheel.spring = loadedStack.wheel_bg_autumn_spring.texture;
      this.textures.wheel.autumn = loadedStack.wheel_bg_autumn_spring.texture;
      this.textures.wheel.summer = loadedStack.wheel_bg_summer.texture;
      this.textures.wheel.winter = loadedStack.wheel_bg_winter.texture;

      return this.textures;
    }).then((textures)=>{
      this.context.Loader.load({ name: "SeasonTimeWheel", url: Wheel}, () => { }).then((gltf) => {
        this.wheelMesh = gltf.scene.children[0];
        this.wheelMesh.rotation.y = - Math.PI / 2;
        //Seasons
        this.wheelMesh.children[0].children.map(child => this.PrepareSeasonButton(child));
        //times
        this.wheelMesh.children[1].children.map(child => this.PrepareTimeButton(child));

        this.instance.add(this.wheelMesh);
      });
    });
    this.instance.scale.set(0.5,0.5,0.5)
  }
  PrepareSeasonButton = (child) => {
    this.AddInteractivity(child); 
    this.textures.current.season = this.textures.seasons.normal;
    child.material = new MeshBasicMaterial({
      map : this.textures.current.season,
      toneMapped : false
    });
    child.isActive = (child.name==this.store.state.area.season)
  }
  PrepareTimeButton = (child) => {
    this.AddInteractivity(child); 
    this.textures.current.wheel = this.textures.wheel.summer;
    child.material = new MeshBasicMaterial({
      map : this.textures.current.wheel,
      toneMapped : false
    });
  }
  
  SetSeason = (state,obj) => {
    if(state == "hovered"){
      //obj.material.color = this.hoverSeasonColor;
      obj.material.map = this.textures.seasons.selected;
    }
    
    if(state == "idle"){
      if(obj.isActive){
        obj.material.map = this.textures.seasons.selected;
      }else{
        //obj.material.color = this.idleColor;
        obj.material.map = this.textures.seasons.normal;
      }
      
    }

    if(state == "selected"){
      this.store.commit("area/ChangeGlobalSettings", {
        setting: "season",
        data: obj.name
      });
    }
  }

  SetTime = (state,obj) => {
    if(state == "hovered"){
      obj.material.color = this.hoverTimeColor;
    }
    
    if(state == "idle"){
      obj.material.color = this.idleColor;
    }

    if(state == "selected"){
      
      var sunPos = 0.5;
      switch(obj.name){
        case "MORGEN":
          sunPos = 0.2;
        break;
        case "VORMITTAG":
          sunPos = 0.35;
        break;
        case "MITTAG":
          sunPos = 0.5;
        break;
        case "NACHMITTAG":
          sunPos = 0.65;
        break;
        case "ABEND":
          sunPos = 0.79;
        break;
        case "NACHT":
          sunPos = 0;
        break;
      }
      
      this.store.commit("area/SetSunPosition" , sunPos);
    }
  }

  AddInteractivity(child){
    
    //child.isClickEnabled = true;
    
    if(child.parent.name == "Seasons"){
      child.setState = (state) =>this.SetSeason(state,child);
    }
    if(child.parent.name == "Times"){
      child.setState = (state)=>this.SetTime(state, child);
    }
    
    this.context.Controls.ActiveObjects.push(child);
  }


  initListener(){
    this.store.watch(state => state.area.season , this.ChangeButtonToSeason);
    this.store.watch(state => state.area.sunPosition , this.ChangeSunIndicator);
  }
  ChangeSunIndicator = (newSunPos) => {
    let sunIndex = Utility.CalculateSolarIndex(newSunPos);
    var angle = 0;
    var rotationColor = this.sunColor;
    switch(sunIndex){
      case 0:
        angle = 90
      break;
      case 1:
        angle = 45
      break;
      case 2:
        angle = 0        
      break;
      case 3:
        angle = 315
      break;
      case 4:
        angle = 270
      break;
      case 5:
        angle = 180
        rotationColor = this.moonColor;
      break;
    }

    var start = this.indicatorRotator.rotation.z;
    var end = angle * Math.PI / 180;

    this.indicator.material.color = rotationColor;

    new TWEEN.Tween({
      rot :start
    })
    .to({
      rot : end
    }, 1000)
    .easing(TWEEN.Easing.Quadratic.Out)
    .onUpdate((v) => {
      this.indicatorRotator.rotation.z = v.rot;
    }).start();

   
  }

  ChangeButtonToSeason = (newSeason) => {
    
    let seasonMat = null
    switch(newSeason){
      case "Spring":
        seasonMat = this.textures.wheel.spring;
      break;
      case "Summer":
        seasonMat = this.textures.wheel.summer;
      break;
      case "Autumn":
        seasonMat = this.textures.wheel.autumn;
      break;
      case "Winter":
        seasonMat = this.textures.wheel.winter;
      break;
    }

    this.wheelMesh.children[1].children.map(child => child.material.map = seasonMat);
    this.wheelMesh.children[0].children.map(child => child.isActive = (child.name==newSeason));
  }
}

export default DashboardWheel;