import mainConfig from '../../../main.config';
import axios from 'axios';

var ScreenUtils = {
    SetClickEnabled: (node, enabled) => {
        if (typeof (node) == "undefined") { return }
        node.isClickEnabled = enabled;
        node.traverse(function (child) {
            if (typeof (child) != "undefined") {
                child.isClickEnabled = enabled;
            }
        });
    },
    GetSolarIndex: (sunPosition) => {
        var index = Math.floor(sunPosition * 6);
        index = index == 6 ? 5 : index;
        return index;
    },
    LoadPageContent(url){
        //console.log("LOAD PAGE CONTENT ",url)
        
            return new Promise((resolve,reject)=>{
                axios.get(mainConfig.CMS_BASE_URL + url).then((response) => {
                    resolve(response);
                })
        });
      }
}
export default ScreenUtils