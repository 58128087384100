import ScreenConstants from '../ScreenConstants'
import ScreenUtils from '../ScreenUtils'

import FontBoldImage from '../../../Fonts/Roboto-Bold.png';
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json';

class SubTabButton {
    constructor(props) {
        this.context = props.context;
        this.store = props.store;
        this.subTab = props.subTab
        this.mesh = null;
        this.buttonWidth = 0.1;
        this.currentColor = ScreenConstants.white;
        this.currentFontColor = ScreenConstants.lightGrayFont;
        this.load();

    }

    load() {

        /*
        buttonContainer
            button(interactive)
                title

        */
    
        this.buttonContainer = new ThreeMeshUI.Block({
            width: this.buttonWidth,
            height: this.buttonWidth,
            backgroundOpacity: 0,
            offset: ScreenConstants.offset,
            justifyContent: 'center',
            alignContent: 'center',
            contentDirection: 'column',
        })

        this.button = new ThreeMeshUI.Block({
            width: this.buttonWidth,
            height: this.buttonWidth,
            backgroundOpacity: 1,
            offset: ScreenConstants.offset,
            backgroundColor: ScreenConstants.white,
            fontFamily: FontBoldJSON,
            fontTexture: FontBoldImage,
            justifyContent: 'center',
            alignContent: 'center',
            fontSize: ScreenConstants.fontMedium
        })

        this.button.setupState({
            state: "idle",
            onSet: () => {
                this.button.set({
                    backgroundColor: this.currentColor,
                    fontColor: this.currentFontColor
                })
            }
        });

        this.button.setupState({
            state: "hovered",
            attributes: {
                backgroundColor: ScreenConstants.lightOrange
            }
        });

        this.button.setupState({
            state: "selected",
            attributes: {
                backgroundColor: ScreenConstants.orange
            },
            onSet: () => {
              this.store.state.audio.controller.PlaySound("menu.click", false);

              console.log(this.subTab);
              this.store.dispatch("content/SetSubTab", this.subTab);
            }
        });

        this.context.Controls.ActiveObjects.push(this.button);
        ScreenUtils.SetClickEnabled(this.button, true);
        this.buttonContainer.add(this.button)

        this.title = new ThreeMeshUI.Text({ content: this.subTab.Subheadline_comp,offset: ScreenConstants.offset })
        this.button.add(
            this.title
        );
        this.button.add(this.title);
        this.setColor();

        this.store.watch(state => state.content.currentSubTab, (newValue, oldValue) => {
          this.setColor();
        })
    }

    setColor = () => {
        if (this.store.state.content.currentSubTab == this.subTab) {
            this.currentColor = ScreenConstants.orange
            this.currentFontColor = ScreenConstants.white
        } else {
            this.currentColor = ScreenConstants.white;
            this.currentFontColor = ScreenConstants.orange;
        }

        this.button.set({
            backgroundColor: this.currentColor,
            fontColor: this.currentFontColor
        })
    }
}

export default SubTabButton