import ThreeMeshUI from "three-mesh-ui";
import ScreenConstants from '../ScreenConstants'
import TilteSubtitle from "./TitleSubtitle";
import FontBoldImage from '../../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json';

import {PVSize, HouseType, OperatorConcept } from "../../../Enum/Enums";
import Utility from "../../../utils";

import ConfigDashboardRadioButton from './ConfigDashboardRadioButton';
import ScreenUtils from "../ScreenUtils";
import DashboardIconButton from "./DashboardIconButton";

//Dacharten
import Icon_Satteldach_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Satteldach.png';
import Icon_Satteldach_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Satteldach.png';
import Icon_Zeltdach_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Zeltdach.png';
import Icon_Zeltdach_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Zeltdach.png';
import Icon_Flachdach_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Flachdach.png';
import Icon_Flachdach_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Flachdach.png';
//PVAnlagen
import Icon_PVGross_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_PVGross.png';
import Icon_PVGross_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_PVGross.png';
import Icon_PVKlein_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_PVKlein.png';
import Icon_PVKlein_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_PVKlein.png';
import Icon_Solarziegel_A from '../../../Icon_PNG/DGSZentrum_IconSet_active_Solarziegel.png';
import Icon_Solarziegel_P from '../../../Icon_PNG/DGSZentrum_IconSet_passive_Solarziegel.png';


import ConfigSegment from "../Configurator/ConfigSegment";
import ConfigDashboardButton from "./ConfigDashboardButton";



class DashboardConfigFamilySettings {
    constructor(props) {
        this.store = props.store
        this.parent = props.parent
        this.width = 0.17
        this.context = props.context
        this.position = props.position
        this.height = props.height
        this.rotation = props.rotation
        this.padding = .01;
        this.segmentHeight = 0.06;

        this.participation_50 = null;
        this.participation_75 = null;
        this.participation_100 = null;
        this.load();
    }
    load() {

        this.container = new ThreeMeshUI.Block({
            width: this.width,
            height: this.height,
            backgroundColor: ScreenConstants.white,
            backgroundOpacity: 1,
            margin: 0.01,
            padding:this.padding
        })
        this.container.position.set(this.position.x, this.position.y, this.position.z);
        this.container.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)
        this.parent.add(this.container)
        //Title
        this.headlineText = new TilteSubtitle({
            width: this.width - (this.padding * 2),
            height: .02,
            container: this.container,
            titleColor: ScreenConstants.orange,
            fontSizeSubtitle: ScreenConstants.fontMedium
        })
        this.headlineText.SetTitle("Hausart bzw. Dachart");


        this.configContainer = new ThreeMeshUI.Block({
          width: this.width - (this.padding * 2),
          height: .05,
          backgroundOpacity: 0,
          backgroundColor: ScreenConstants.white,
          alignContent: 'start',
          contentDirection: 'row',
          justifyContent: 'start',
        });

        this.container.add(this.configContainer);


        const button = new DashboardIconButton(this.store, this.context,{
          name: "Satteldach",
          type: HouseType.Sattel,
          icon_active: Icon_Satteldach_A,
          icon_inactive: Icon_Satteldach_P,
        },"houseType");

        this.configContainer.add(button.buttonContainer);
        
        const button1 = new DashboardIconButton(this.store, this.context,{
          name: "Zeltdach",
          type: HouseType.Zelt,
          icon_active: Icon_Zeltdach_A,
          icon_inactive: Icon_Zeltdach_P
        },"houseType");

        this.configContainer.add(button1.buttonContainer);
        
        this.flachButton = new DashboardIconButton(this.store, this.context,{
          name: "Flach",
          type: HouseType.Flach,
          icon_active: Icon_Flachdach_A,
          icon_inactive: Icon_Flachdach_P,
          constraint: PVSize.PVZiegel
        },"houseType");

        this.configContainer.add(this.flachButton.buttonContainer);

        var platzhalter = new ThreeMeshUI.Block({
          width: this.width,
          height: .02,
          backgroundColor: ScreenConstants.white,
          backgroundOpacity: 0
        })
        this.container.add(platzhalter);


        this.solarZiegel = new ConfigDashboardButton({
          store: this.store, 
          parent: this.container, 
          context: this.context, 
          title: 'Solarziegel', 
          width: this.width - (this.padding * 2), 
          storeRef: "pvSize", 
          trueState: PVSize.PVZiegel, 
          falseState: PVSize.PVGross
        })

        //Title
        this.pvText = new TilteSubtitle({
          width: this.width - (this.padding * 2),
          height: .02,
          container: this.container,
          titleColor: ScreenConstants.orange,
          fontSizeSubtitle: ScreenConstants.fontMedium
        })
        this.pvText.SetTitle("Größe der PV-Anlage");


        this.pvSizeButtonGross = new ConfigDashboardButton({
          store: this.store, 
          parent: this.container, 
          context: this.context, 
          title: 'Gross', 
          width: this.width - (this.padding * 2), 
          storeRef: "pvSize", 
          trueState: PVSize.PVGross, 
          falseState: PVSize.PVZiegel
        })
        
        this.pvSizeButtonKlein = new ConfigDashboardButton({
          store: this.store, 
          parent: this.container, 
          context: this.context, 
          title: 'Klein', 
          width: this.width - (this.padding * 2), 
          storeRef: "pvSize", 
          trueState: PVSize.PVKlein, 
          falseState: PVSize.PVZiegel
        })







        
        this.store.watch(state => state.area.houseType, (newValue, oldValue) => {
          if(this.store.state.area.houseType == 'Flach'){
            this.solarZiegel.buttonIcon.set({
              backgroundImage : this.solarZiegel.iconInactive
            });
            this.solarZiegel.textContainer.set({
              fontColor: ScreenConstants.lightGrayFont
            });
            ScreenUtils.SetClickEnabled( this.solarZiegel.button , false);
          }else{
            this.solarZiegel.buttonIcon.set({
              backgroundImage : this.store.state.area.pvSize == PVSize.PVZiegel ?  this.solarZiegel.iconActive : this.solarZiegel.iconInactive
            });
            this.solarZiegel.textContainer.set({
              fontColor: ScreenConstants.darkGrayFont
            })
            ScreenUtils.SetClickEnabled( this.solarZiegel.button , true);
            
          }
        })
        
        this.store.watch(state => state.area.pvSize, (newValue, oldValue) => {
          if(this.store.state.area.pvSize == PVSize.PVZiegel){
           
            //PVGRoss Button
            this.pvSizeButtonGross.buttonIcon.set({
              backgroundImage : this.pvSizeButtonGross.iconInactive
            });
            this.pvSizeButtonGross.textContainer.set({
              fontColor: ScreenConstants.lightGrayFont
            })
            
            //PVKlein Button
            this.pvSizeButtonKlein.buttonIcon.set({
              backgroundImage : this.pvSizeButtonKlein.iconInactive
            });
            this.pvSizeButtonKlein.textContainer.set({
              fontColor: ScreenConstants.lightGrayFont
            });

            ScreenUtils.SetClickEnabled( this.flachButton.buttonContainer , false);
            ScreenUtils.SetClickEnabled( this.pvSizeButtonGross.button , false);
            ScreenUtils.SetClickEnabled( this.pvSizeButtonKlein.button , false);


            
          }else{
           
            //PVGRoss Button
            this.pvSizeButtonGross.buttonIcon.set({
              backgroundImage : this.store.state.area.pvSize == PVSize.PVGross ?  this.pvSizeButtonGross.iconActive : this.pvSizeButtonGross.iconInactive
            });
            this.pvSizeButtonGross.textContainer.set({
              fontColor: ScreenConstants.darkGrayFont
            })
            
            //PVGKlein Button
            this.pvSizeButtonKlein.buttonIcon.set({
              backgroundImage : this.store.state.area.pvSize == PVSize.PVKlein ?  this.pvSizeButtonKlein.iconActive : this.pvSizeButtonKlein.iconInactive
            });
            this.pvSizeButtonKlein.textContainer.set({
              fontColor: ScreenConstants.darkGrayFont
            })

            ScreenUtils.SetClickEnabled(  this.flachButton.buttonContainer , true);
            ScreenUtils.SetClickEnabled( this.pvSizeButtonGross.button , true);
            ScreenUtils.SetClickEnabled( this.pvSizeButtonKlein.button , true);

          }
        });

      //   this.store.watch(state => state.area.participationRate, (newValue, oldValue) => {
      //       this.UpdateText()
      //   })

    }

    UpdateText() {
      // var rate = this.store.state.area.participationRate;

      // if(this.store.state.area.current == null || 
      //     this.participation_50 == null ||
      //     this.participation_75 == null ||
      //     this.participation_100 == null){ return; }

      //   switch(this.store.state.area.operatorConcept){
      //     case OperatorConcept.OnlyOperatorCurrent:
      //       this.participation_50.set({
      //         backgroundColor: ScreenConstants.lightGray,
      //         fontColor : ScreenConstants.white
      //       });
      //       this.participation_75.set({
      //         backgroundColor: ScreenConstants.lightGray,
      //         fontColor : ScreenConstants.white
      //       });
      //       this.participation_100.set({
      //         backgroundColor: ScreenConstants.lightGray,
      //         fontColor : ScreenConstants.white
      //       });
      //       ScreenUtils.SetClickEnabled(this.participation_50, false);
      //       ScreenUtils.SetClickEnabled(this.participation_75, false);
      //       ScreenUtils.SetClickEnabled(this.participation_100, false);
      //     break;
      //     case OperatorConcept.OperatorCurrentAndCommunity:
      //       this.participation_50.set({
      //         backgroundColor: rate == .5 ? ScreenConstants.orange : ScreenConstants.darkGrayFont,
      //         fontColor : ScreenConstants.white
      //       });
      //       this.participation_75.set({
      //         backgroundColor: rate == .75 ? ScreenConstants.orange : ScreenConstants.darkGrayFont,
      //         fontColor : ScreenConstants.white
      //       });
      //       this.participation_100.set({
      //         backgroundColor: rate == 1 ? ScreenConstants.orange : ScreenConstants.darkGrayFont,
      //         fontColor : ScreenConstants.white
      //       });
      //       ScreenUtils.SetClickEnabled(this.participation_50, true);
      //       ScreenUtils.SetClickEnabled(this.participation_75, true);
      //       ScreenUtils.SetClickEnabled(this.participation_100, true);
      //     break;
      //   }
    }

}
export default DashboardConfigFamilySettings;