import {Color} from 'three';

const ScreenConstants = {

    white : new Color('#FFFFFF'),
    lightGray : new Color('#F8F8F8'),
    darkGray : new Color('#E5E9EB'),
    darkGrayFont : new Color('#303448'),
    lightGrayFont : new Color('#C2C2C2'),
    width : 0.4,
    height: 0.37,
    orange : new Color('#F38D2F'),
    lightOrange : new Color('#F9C697'),
    tomato: new Color('#FF6347'),
    blue: new Color('#3F4355'),
    lightBlue: new Color('#31C5F4'),
    fontExtraBig: 0.1,
    fontBig: 0.046,
    fontMedium: 0.016,
    fontDashboard: 0.017,
    fontConfig: 0.028,
    fontArticle: 0.07,
    offset: 0.001,
    widthGlobalSettings: 0.2,
    heightGlobalSettings: 0.12,
    interLine: 0.005,
    menuColor: new Color('#222334'),
}

export default ScreenConstants;