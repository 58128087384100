import ThreeMeshUI from 'three-mesh-ui';
import ScreenConstants from '../ScreenConstants';
import FontBoldImage from '../../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json';

import {TextureLoader} from 'three';


class ConfigButton {
  constructor(store, context, buttonInfo, category) {
    this.store = store;
    this.context = context;
    this.name = buttonInfo.name;
    this.type = buttonInfo.type;
    this.btn = buttonInfo;
    this.category = category;
    this.buttonContainer = null;
    this.button = null;
    this.iconActive = null;
    this.iconInactive = null;
    this.init();
    // this.currentColor = ScreenConstants.lightGray;
    // this.currentFontColor = ScreenConstants.lightGrayFont;
  }
  init = () => {
    /*buttoncontainer
        iconcontainer
        titlecontainer
            title
        */

    this.buttonContainer = new ThreeMeshUI.Block({
      width: 0.18,
      height: 0.18,
      offset: ScreenConstants.offset
    })


    this.iconContainer = new ThreeMeshUI.Block({
      width: 0.13,
      height: 0.13,
      offset: ScreenConstants.offset,
      backgroundOpacity: 1,
      backgroundColor: ScreenConstants.white,
    })

    

    this.buttonContainer.add(this.iconContainer);

    this.titleContainer = new ThreeMeshUI.Block({
      width: 0.18,
      height: 0.05,
      backgroundOpacity: 0,
      offset: ScreenConstants.offset,
      fontSize: ScreenConstants.fontConfig,
      fontColor: ScreenConstants.orange,
      fontFamily: FontBoldJSON,
      fontTexture: FontBoldImage,
    });

    this.title = new ThreeMeshUI.Text({
      content: this.name,
      offset: ScreenConstants.offset,
    })

    this.titleContainer.add(
      this.title
    );

    this.buttonContainer.add(this.titleContainer);


    this.buttonContainer.setupState({
      state: "selected",
      attributes: {
        backgroundColor: ScreenConstants.orange
      },
      onSet: () => {
        this.store.state.audio.controller.PlaySound("menu.click", false);

        console.log("Select ConfigButton" , this.category,  this.type);

        this.store.commit("area/ChangeGlobalSettings", { setting: this.category, data: this.type });
      }
    });

    this.buttonContainer.setupState({
      state: "idle",
      onSet: () => {
        this.buttonContainer.set({
          backgroundColor: this.currentColor,
          fontColor: this.currentFontColor
        })
      }
    });

    this.buttonContainer.setupState({
      state: "hovered",
      attributes: {
        backgroundColor: ScreenConstants.lightOrange
      }
    });

    this.context.Controls.ActiveObjects.push(this.buttonContainer);

    //LOAD TEXTURES
    const loader = new TextureLoader();
    const prom1 = new Promise((resolve)=>{
      loader.load(this.btn.icon_inactive, icon => {
        this.iconInactive = icon;
        resolve();
      });
    });

    const prom2 = new Promise((resolve)=>{
      loader.load(this.btn.icon_active, icon => {
        this.iconActive = icon;
        resolve();
      });
    });

    Promise.all([prom1, prom2]).then((values) => {
      this.setColor();
    });

    //WATCHERS
    if(this.category == 'pvSize'){
      this.store.watch(state => state.area.houseType, (newValue, oldValue) => {
        this.setColor();
      })
    }else if(this.category == 'houseType'){
      this.store.watch(state => state.area.pvSize, (newValue, oldValue) => {
        this.setColor();
      })
    }

    this.store.watch(state => state.area[this.category], (newValue, oldValue) => {
      this.setColor();
    })

    this.store.watch(state => state.viewMode, (newValue, oldValue) => {
      this.setColor();
    })


  }
  disable = () => {
    this.currentColor = ScreenConstants.darkGray;
    this.currentFontColor = ScreenConstants.white;
    this.currentIcon = this.iconActive;
  }


  setColor = () => {

    if (this.type == 'Flach' && this.store.state.area.pvSize == 'PVZiegel') {
      this.disable();
    } else if (this.type == 'PVZiegel' && this.store.state.area.houseType == 'Flach') {
      this.disable();
    }else{

      if (this.store.state.area[this.category] == this.type) {
        this.currentColor = ScreenConstants.orange;
        this.currentFontColor = ScreenConstants.lightGray;
        this.currentIcon = this.iconActive;
  
      } else {
        this.currentColor = ScreenConstants.lightGray;
        this.currentFontColor = ScreenConstants.lightGrayFont;
        this.currentIcon = this.iconInactive;
  
      }
    }

    
    this.buttonContainer.set({
      backgroundColor: this.currentColor,
    });
    this.titleContainer.set({
      fontColor: this.currentFontColor,
    });
    this.iconContainer.set({
      backgroundTexture: this.currentIcon
    });
  }

}
export default ConfigButton;