import ScreenUtils from '../ScreenUtils';
import Play from '../../../PNG/Play.png'
import Pause from '../../../PNG/Pause.png';
import Stop from '../../../PNG/Stop.png';
import ScreenConstants from '../ScreenConstants';
import ThreeMeshUI from 'three-mesh-ui';

import {Group,TextureLoader} from 'three';


class AnimationPanel {
    constructor(props) {
        this.context = props.context;
        this.store = props.store;
        this.container = props.container;
        this.load();
    }

    load() {
        /*
        playBtnContainer (Group)
            playBtn 0.8
                stopIcon
                playIcon 0.15
                progressBar 0.6

        */

        //Play Button
        this.playBtnContainer = new Group();
        this.playBtnContainer.visible = false;

        this.playBtn = new ThreeMeshUI.Block({
            width: 1,
            height: 0.2,
            backgroundOpacity: 1,
            justifyContent: 'start',
            contentDirection: 'row',
            
            backgroundColor: ScreenConstants.blue,
        })

        const iconSettings = {
            width: 0.15,
            height: 0.15,
        }

        this.playIcon = new ThreeMeshUI.Block(iconSettings)
        this.stopIcon = new ThreeMeshUI.Block(iconSettings)

        const loader = new TextureLoader();
        loader.load(Play, icon => {
            this.iconPlay = icon;
            this.playIcon.set({
                backgroundTexture: this.iconPlay
            })
        });
        loader.load(Pause, icon => {
            this.iconPause = icon;
        });

        loader.load(Stop, icon => {
            this.stopIcon.set({
                backgroundTexture: icon
            })
        });


        this.playBtn.add(this.playIcon);
        this.playBtn.add(this.stopIcon);
        this.playBtnContainer.add(this.playBtn);
        this.playBtnContainer.position.set(0, -0.8, 0.001);

        this.playIcon.setupState({
            state: "selected",
            onSet: () => {
                this.store.state.audio.controller.PlaySound("menu.click", false);

                if (!this.store.state.animation.isPlaying) {
                    this.store.state.world.mainScene.AnimationController.Play();
                    this.playIcon.set({
                        backgroundTexture: this.iconPause
                    })

                } else {
                  this.store.state.world.mainScene.AnimationController.Pause();
                  this.playIcon.set({
                      backgroundTexture: this.iconPlay
                  })
                }
            }
        });

        this.playIcon.setupState({
            state: "idle",
        });

        this.playIcon.setupState({
            state: "hovered",
        });

        this.stopIcon.setupState({
            state: "selected",
            onSet: () => {
                this.store.state.audio.controller.PlaySound("menu.click", false);
                this.store.state.world.mainScene.AnimationController.ResetAnimation();
            }
        });

        this.stopIcon.setupState({
            state: "idle",
        });

        this.stopIcon.setupState({
            state: "hovered",
        });

        this.context.Controls.ActiveObjects.push(this.playIcon);
        ScreenUtils.SetClickEnabled(this.playIcon, true);

        this.context.Controls.ActiveObjects.push(this.stopIcon);
        ScreenUtils.SetClickEnabled(this.stopIcon, true);

        //ProgressBar
        this.progressBar = new ThreeMeshUI.Block({
            width: 0.0001,
            height: 0.05,
            backgroundOpacity: 1,
            backgroundColor: ScreenConstants.orange
        })
        this.progressBar.visible = false;

        this.playBtn.add(this.progressBar);

        this.container.add(this.playBtnContainer);

        this.store.watch(state => state.animation.Progress, (newValue, oldValue) => {
          if(newValue == 0){
            this.playIcon.set({
              backgroundTexture: this.iconPlay
            });
          }

          if(newValue <= 0){
            this.progressBar.visible = false;
          }else{
            this.progressBar.visible = true;
            this.progressBar.set({
              width: newValue * 0.6
            })
          }
        });
    }
    SetVisibility(visible) {
        this.playBtnContainer.visible = visible
        if (visible) {
            this.context.Events.addEventListener("OnAnimationLoop", this.UpdateProgressBar);
        } else {
            this.context.Events.removeEventListener("OnAnimationLoop");
        }
    }
    Reset() {
      this.playIcon.set({
          backgroundTexture: this.iconPlay
      })
    }

    UpdateProgressBar = ()=>{
        //console.log("Animation Progress ", this.store.state.animation.Progress)
        // if(this.store.state.animation.Progress <= 0){
        //     this.progressBar.visible = false;
        // }else{
        //     this.progressBar.visible = true;
        //     this.progressBar.set({
        //       width: this.store.state.animation.Progress * 0.6
        //     })
        // }
        
    }

}

export default AnimationPanel